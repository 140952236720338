import { isNil } from "lodash";
import store from "../store";

let isIosOnBrowser =
  ["iPhone", "iPad", "iPod"].includes(navigator.platform) &&
  !window.navigator.standalone;

if (isIosOnBrowser) {
  const now = Date.now();
  let limitDate = null;
  const addToHomeIosPromptLastDate = localStorage.getItem(
    "addToHomeIosPromptLastDate"
  );

  if (!isNil(addToHomeIosPromptLastDate)) {
    limitDate = new Date(parseInt(addToHomeIosPromptLastDate, 10));
    limitDate.setMonth(limitDate.getMonth() + 1);
  }

  if (isNil(limitDate) || now >= limitDate.getTime()) {
    store.commit("setShowAddToHomeScreenModalForApple", true);
  }
}
