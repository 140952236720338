<template>
  <modal name="lopd" height="auto" class="form-modal" :scrollable="true">
    <div>
      <h3 class="font-bold pt-3">Datos identificativos</h3>
      <p>Usted está visitando la página web www.mybadgesmarker.es</p>
      <p>
        Puede contactar con el TITULAR por cualquiera de los siguientes medios:
      </p>
      <p>
        Correo electrónico de contacto:
        <u>
          <a href="mailto:formacion@advservices.es">formacion@advservices.es</a>
        </u>
      </p>
      <p>Empresa: ADVANCED FACILITY SERVICES CIF: B15805419</p>
      <p>
        <u>
          <a href="https://advservices.es/contacto/">
            https://advservices.es/contacto/
          </a>
        </u>
      </p>
      <h3 class="font-bold pt-3">Usuarios</h3>
      <p>
        Las presentes condiciones (en adelante Aviso Legal) tiene por finalidad
        regular el uso de la página web de EL TITULAR que pone a disposición del
        público.
      </p>
      <p>
        El acceso y/o uso de esta página web atribuye la condición de USUARIO,
        que acepta, desde dicho acceso y/o uso, las condiciones generales de uso
        aquí reflejadas. Las citadas condiciones serán de aplicación
        independientemente de las condiciones generales de contratación que en
        su caso resulten de obligado cumplimiento.
      </p>
      <h3 class="font-bold pt-3">Uso del portal</h3>
      <p>
        www.mybadgesmarker.es proporciona acceso a informaciones, programas y
        dados en Internet pertenecientes a EL TITULAR o a sus licenciantes a los
        que el USUARIO puede tener acceso.
      </p>
      <p>
        El usuario asume la responsabilidad del uso del portal. Dicha
        responsabilidad se extiende al registro que fuese necesario para acceder
        a determinados servicios o contenidos. En dicho registro el USUARIO será
        responsable de aportar información veraz y lícita. Como consecuencia de
        este registro, al USUARIO se le puede proporcionar una contraseña de la
        que será responsable, comprometiéndose a hacer un uso diligente y
        confidencial de la misma.
      </p>
      <p>
        El USUARIO se compromete a hacer un uso adecuado de los contenidos y
        servicios que EL TITULAR ofrece a través de su portal y con carácter
        enunciativo pero no limitativo, a no emplearlos para:
      </p>
      <ul>
        <li>
          <p>
            Incurrir en actividades ilícitas, ilegales o contrarias a la buena
            fe y al orden público.
          </p>
        </li>
        <li>
          <p>
            Difundir contenidos o propaganda racista, xenófoba,
            pornográfico-ilegal, de apología del terrorismo o atentatoria contra
            los derechos humanos.
          </p>
        </li>
        <li>
          <p>
            Provocar daños en los sistemas físicos y lógicos de sus proveedores
            o de terceras personas, introducir o difundir en l red virus
            informáticos o cualesquiera otros sistemas físicos o lógicos que
            sean susceptibles de provocar los daños anteriormente mencionados.
          </p>
        </li>
        <li>
          <p>
            Intentar acceder y, en su caso, utilizar las cuentas de correo
            electrónico de otros usuarios y modificar o manipular sus mensajes.
          </p>
        </li>
        <li>
          <p>
            Utilizar el sitio web ni las informaciones que en él se contienen
            con fines comerciales, políticos, publicitarios y para cualquier uso
            comercial, sobre todo en el envío de correos electrónicos no
            solicitados.
          </p>
        </li>
      </ul>
      <p>
        EL TITULAR se reserva el derecho a retirar todos aquellos comentarios y
        aportaciones que vulneren el respeto a la dignidad de la persona, que
        sean discriminatorios, xenófobos, racistas pornográficos, que atenten
        contra la juventud o la infancia, el orden o la seguridad pública o que,
        a su juicio, no resultarán adecuados para su publicación. En cualquier
        caso, EL TITULAR no será responsable de las opiniones vertidas por los
        usuarios a través de los foros, chats, u otras herramientas de
        participación.
      </p>
      <h3 class="font-bold pt-3">Protección de datos</h3>
      <p>
        EL TITULAR con relación al USUARIO con licencia, así como el USUARIO con
        licencia en relación a los USUARIOS en modo ALUMNO (opción de solo
        consulta, no se intercambian ni registran datos) llevarán a cabo la
        legislación vigente tanto estatal como autonómica. Así pues, la
        protección de datos se regirá por el artículo 18 de la Constitución
        española, el Reglamento General de Protección de Datos (RGPD), la Ley
        Orgánica 03/2018, del 5 de diciembre, de Protección de Datos y
        Personales y Garantía de Derechos Digitales (LOPDPGD) y la Resolución
        del 28 de junio de la Subsecretaría de la Consellería d’Educació, por la
        que se dictan instrucciones para el cumplimiento de la normativa de
        protección de datos en los centros educativos públicos de titularidad de
        la Generalidad Valenciana. Así pues, EL TITULAR y USUARIO aceptan los
        términos de protección de datos en base a la legislación vigente.
      </p>
      <h3 class="font-bold pt-3">
        Contenidos. Propiedad intelectual e industrial
      </h3>
      <p>
        EL TITULAR es propietario de todos los derechos de propiedad intelectual
        e industrial de su página web, así como de los elementos contenidos en
        la misma (a título enunciativo: imágenes, fotografías, sonido, audio,
        vídeo, software o textos; marcas o logotipos, combinaciones de colores,
        estructura y diseño, selección de materiales usados, programas de
        ordenador necesarios para su funcionamiento, acceso y uso, etc),
        titularidad del TITULAR o bien de sus licenciantes.
      </p>
      <p>
        Todos los derechos reservados. En virtud de lo dispuesto en los
        artículos 8 y 32.1, párrafo segundo, de la Ley de Propiedad Intelectual,
        quedan expresamente prohibidas la reproducción, la distribución y la
        comunicación pública, incluida su modalidad de puesta a disposición, de
        la totalidad o parte de los contenidos de esta página web, con fines
        comerciales, en cualquier soporte y por cualquier medio técnico, sin la
        autorización del TITULAR.
      </p>
      <h3 class="font-bold pt-3">Exclusión de garantías y responsabilidad</h3>
      <p>
        EL USUARIO reconoce que la utilización de la página web y de sus
        contenidos y servicios se desarrolla bajo su exclusiva responsabilidad.
        En concreto, a título meramente enunciativo, EL TITULAR no asume ninguna
        responsabilidad en los siguientes ámbitos:
      </p>
      <p>
        La disponibilidad del funcionamiento de la página web, sus servicios y
        contenidos y su calidad o interoperabilidad.
      </p>
      <p>
        La finalidad para la que la página web sirva a los objetivos del
        USUARIO.
      </p>
      <p>
        La infracción de la legislación vigente por parte del USUARIO o terceros
        y, en concreto, de los derechos de propiedad intelectual e industrial
        que sean titularidad de otras personas o entidades.
      </p>
      <p>
        La existencia de códigos maliciosos o cualquier otro elemento
        informático dañino que pudiera causar el sistema informático del USUARIO
        o de terceros. Corresponde al USUARIO, en todo caso, disponer de
        herramientas adecuadas para la detección y desinfección de estos
        elementos.
      </p>
      <p>
        El acceso fraudulento a los contenidos o servicios por terceos no
        autorizados, o, en su caso, la captura, eliminación, alteración,
        modificación o manipulación de los mensajes y comunicaciones de
        cualquier clase que dichos terceros pudiera realizar.
      </p>
      <p>
        La exactitud, veracidad, actualidad y utilidad de los contenidos y
        servicios ofrecidos y la utilización posterior que de ellos haga el
        USUARIO. EL TITULAR empleará todos los esfuerzos y medios razonables
        para facilitar la información actualizada y fehaciente.
      </p>
      <p>
        Los daños producidos a equipos informáticos durante el acceso a la
        página web y los daños producidos a los USUARIOS cuando tengan su origen
        en fallos o desconexiones en las redes de telecomunicaciones que
        interrumpan el servicio.
      </p>
      <p>
        Los daños o perjuicios que se deriven de circunstancias acaecidas por
        caso fortuito o fuerza mayor.
      </p>
      <p>
        En caso de que existan foros, el uso de los mismos u otros espacios
        análogos, ha de tenerse en cuenta que los mensajes reflejen únicamente
        la opinión del USUARIO que los remite, que es el único responsable. EL
        TITULAR no se hace responsable del contenido de los mensajes enviados
        por el USUARIO.
      </p>
      <h3 class="font-bold pt-3">
        Modificación de este aviso legal y duración
      </h3>
      <p>
        EL TITULAR se reserva el derecho de efectuar sin previo aviso las
        modificaciones que considere oportunas en su portal, pudiendo cambiar,
        suprimir o añadir tantos contenidos y servicios que se presten a través
        de la misma, como la forma en la que éstos aparezcan representados o
        localizados en su portal.
      </p>
      <p>
        La vigencia de las citadas condiciones irá en función de su exposición y
        estarán vigentes hasta que sean modificadas por otras debidamente
        publicadas.
      </p>
      <h3 class="font-bold pt-3">Enlaces</h3>
      <p>
        En el caso de que en www.mybadgesmarker.es se incluyesen enlaces o
        hipervínculos hacia otros sitios de Internet, EL TITULAR no ejercerá
        ningún tipo de control sobre dichos sitios y contenidos. En ningún caso
        EL TITULAR asumirá responsabilidad alguna por los contenidos de algún
        enlace perteneciente a un sitio web ajeno, ni garantizará la
        disponibilidad técnica, calidad, fiabilidad, exactitud, amplitud,
        veracidad, validez y constitucionalidad de cualquier materia o
        información contenida en ninguno de dichos hipervínculos y otros sitios
        en Internet. Igualmente, la inclusión de estas conexiones externas no
        implicará ningún tipo de asociación, fusión o participación con las
        entidades conectadas.
      </p>
      <h3 class="font-bold pt-3">Derechos de exclusión</h3>
      <p>
        EL TITULAR ser reserva el derecho a denegar o retirar el acceso a portal
        y/o los servicios ofrecidos sin necesidad de advertencia previa, a
        instancia propia o de un tercero, a aquellos usuarios que incumplan el
        contenido de este aviso legal.
      </p>
      <h3 class="font-bold pt-3">Generalidades</h3>
      <p>
        EL TITULAR perseguirá el incumplimiento de las presentes condiciones,
        así como cualquier utilización indebida de su portal ejerciendo todas
        las acciones civiles y penales que le puedan corresponder en derecho.
      </p>
      <h3 class="font-bold pt-3">Legislación aplicable y jurisdicción</h3>
      <p>
        La relación entre EL TITULAR y EL USUARIO se regirá por la normativa
        española vigente. Todas las disputas y reclamaciones derivadas de este
        aviso legal se resolverán por los juzgados y tribunales españoles. Del
        mismo modo
      </p>
      <h3 class="font-bold pt-3">Menores de edad</h3>
      <p>
        www.mybadgesmarker.es dirige sus servicios a USUARIOS con licencia
        mayores de 18 años. Los menores de esta edad no están autorizados a
        utilizar nuestros servicios con categoría de USUARIO con licencia y no
        deberán, por tanto, enviarnos sus datos personales. Informamos de que,
        si se da tal circunstancia, EL TITULAR no se hace responsable de las
        posibles consecuencias que pudieran derivarse del incumplimiento del
        aviso que en esta misma cláusula se establece.
      </p>
      <p>
        No obstante, los menores de edad pueden utilitzar la
        <u><a href="http://www.mybadgesmarker.es/">www.mybadgesmarker.es</a></u>
        con el permsio del USUARIO con licencia y con el modo “ALUMNO” de esta
        página web, de modo que en ningún caso se comparte ningún dato personal
        de los mismos, atendiéndonos al apartado de protección de datos.
      </p>
    </div>
    <button @click="close">
      <span class="text-button">Volver</span>
    </button>
  </modal>
</template>

<script>
export default {
  methods: {
    close() {
      this.$modal.hide("lopd");
    },
  },
};
</script>

<style>
</style>