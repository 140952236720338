import { db, auth, emailauthprovider } from "./firebase";
import store from "../store";
import firebase from "firebase";
import _ from "lodash";
// import helpers from "./helpers";

export default {
  login(email, password, newUser = false) {
    let el = this;
    return new Promise(function(resolve, reject) {
      auth
        .signInWithEmailAndPassword(email, password)
        .then((data) => {
          if (newUser) {
            el.addUser(data.user.uid, newUser.role).then(() => {
              data.user.role = newUser.role;
              data.user.subscription = false;
              store.dispatch("fetchUser", data.user);
              resolve(data.user);
            });
          } else {
            el.getUser(data.user.uid)
              .then((user) => {
                data.user.role = user.role;
                data.user.subscription = user.subscription;
                store.dispatch("fetchUser", data.user);
                resolve(data.user);
              })
              .catch(() =>
                el.addUser(data.user.uid).then(() => {
                  data.user.role = "free";
                  data.user.subscription = false;
                  store.dispatch("fetchUser", data.user);
                  resolve(data.user);
                })
              );
          }
        })
        .catch((err) => {
          store.dispatch("fetchUser", null);
          reject(err.message);
        });
    });
  },

  refreshSubscription(userID) {
    this.getUser(userID).then((user) => {
      if (user.subscription)
        store.dispatch("updateSubscription", user.subscription);
    });
  },

  register(email, password) {
    return new Promise(function(resolve, reject) {
      auth
        .createUserWithEmailAndPassword(email, password)
        .then((data) => {
          resolve(data);
        })
        .catch((error) => {
          // Handle Errors here.
          let errorCode = error.code;
          let errorMessage = error.message;
          switch (errorCode) {
            case "auth/email-already-in-use":
              reject("Ya existe una cuenta con esta dirección de correo");
              break;
            case "auth/invalid-email":
              reject("Has introducido una dirección de correo invalida.");
              break;
            case "auth/weak-password":
              reject("La contraseña debe de tener al menos 6 carácteres.");
              break;
            default:
              reject(errorMessage);
          }
        });
    });
  },

  changePassword(actualPassword, newPassword) {
    return new Promise(function(resolve, reject) {
      let errorMessage = "";
      const user = auth.currentUser;
      const credential = emailauthprovider.EmailAuthProvider.credential(
        user.email,
        actualPassword
      );
      user
        .reauthenticateWithCredential(credential)
        .then(() => {
          user
            .updatePassword(newPassword)
            .then(() => resolve())
            .catch((error) => {
              errorMessage = error.message;
              reject(errorMessage);
            });
        })
        .catch((error) => {
          errorMessage = error.message;
          reject(errorMessage);
        });
    });
  },

  sendPasswordResetEmail(email) {
    return new Promise(function(resolve, reject) {
      auth
        .sendPasswordResetEmail(email)
        .then((data) => {
          resolve(data);
        })
        .catch((error) => {
          // Handle Errors here.
          let errorCode = error.code;
          let errorMessage = error.message;
          switch (errorCode) {
            case "auth/user-not-found":
              reject("No existe ninguna cuenta con esta dirección de correo");
              break;
            case "auth/invalid-email":
              reject("Has introducido una dirección de correo invalida.");
              break;

            default:
              reject(errorMessage);
          }
        });
    });
  },

  verifyPasswordResetCode(code) {
    return new Promise(function(resolve, reject) {
      auth
        .verifyPasswordResetCode(code)
        .then((email) => {
          resolve(email);
        })
        .catch((error) => {
          // Handle Errors here.
          let errorCode = error.code;
          let errorMessage = error.message;
          switch (errorCode) {
            case "auth/expired-action-code":
              reject("El link ha expirado. Vuelve a solicitar un link");
              break;
            case "auth/invalid-action-code":
              reject("El link no es válido o ya ha sido usado.");
              break;
            default:
              reject(errorMessage);
          }
        });
    });
  },

  confirmPasswordReset(code, newPassword) {
    return new Promise(function(resolve, reject) {
      auth
        .confirmPasswordReset(code, newPassword)
        .then(() => {
          resolve();
        })
        .catch((error) => {
          let errorCode = error.code;
          switch (errorCode) {
            case "auth/expired-action-code":
              reject("El link ha expirado. Vuelve a solicitar un link");
              break;
            default:
              reject(false);
          }
        });
    });
  },

  updateProfile(data) {
    return new Promise(function(resolve, reject) {
      const user = auth.currentUser;
      user
        .updateProfile(data)
        .then(() => {
          store.dispatch("updateUserProfile", user);
          resolve();
        })
        .catch(() => {
          reject(false);
        });
    });
  },

  async addUser(userID, role = "free") {
    return new Promise(function(resolve, reject) {
      db.collection("users")
        .doc(userID)
        .set({ role })
        .then(function() {
          resolve();
        })
        .catch(function(error) {
          reject("Error adding user: ", error);
        });
    });
  },

  async getUser(userID) {
    try {
      var user = {};
      var userRef = db.collection("users").doc(userID);
      var userSnap = await userRef.get();
      var subscription = false;
      user = userSnap.data();
      var subscriptionsSnaf = await userRef
        .collection("subscriptions")
        .where("status", "in", ["trialing", "active"])
        .get();

      if (subscriptionsSnaf.docs[0])
        subscription = subscriptionsSnaf.docs[0].data();

      if (subscription) user.role = subscription.role;
      user.subscription = _.clone(subscription);

      return user;
    } catch (e) {
      //console.log("Error here", e);
    }
  },

  async startSubscription(userID, type = "anual") {
    try {
      let product = {
        price:
          type === "anual"
            ? process.env.VUE_APP_STRIPE_PRODUCT_ANUAL
            : process.env.VUE_APP_STRIPE_PRODUCT_TRIMESTRAL,
        success_url: window.location.origin,
        cancel_url: window.location.origin + "/profile",
        allow_promotion_codes: true,
      };

      const docRef = await db
        .collection("users")
        .doc(userID)
        .collection("checkout_sessions")
        .add(product);
      // Wait for the CheckoutSession to get attached by the extension
      docRef.onSnapshot((snap) => {
        const { error, url } = snap.data();
        if (error) {
          // Show an error to your customer and
          // inspect your Cloud Function logs in the Firebase console.
          alert(`An error occured: ${error.message}`);
        }
        if (url) {
          // We have a Stripe Checkout URL, let's redirect.
          window.location.assign(url);
          return;
        }
      });
    } catch (e) {
      //console.log("Error");
    }
  },
  async redirectCustomerPortal() {
    const functionRef = firebase
      .app()
      .functions(process.env.VUE_APP_CLOUDFUNCTIONS_LOCATION)
      .httpsCallable("ext-firestore-stripe-subscriptions-createPortalLink");
    const { data } = await functionRef({
      returnUrl: window.location.origin + "/profile",
      locale: "auto", // Optional, defaults to "auto"
      //configuration: "bpc_1JSEAKHYgolSBA358VNoc2Hs", // Optional ID of a portal configuration: https://stripe.com/docs/api/customer_portal/configuration
    });
    window.location.assign(data.url);
  },

  async getClassRoom(classroomCode) {
    try {
      var classroom = {};
      var classroomRef = db.collection("classroms").doc(classroomCode);
      var classroomSnap = await classroomRef.get();
      classroom = classroomSnap.data();
      classroom.id = classroomSnap.id;
      return classroom;
    } catch (e) {
      // console.error("Upss, ha fallado algo.");
    }
  },

  async existsClassRoom(classroomCode) {
    try {
      var classroomRef = db.collection("classroms").doc(classroomCode);
      var classroomSnap = await classroomRef.get();
      if (classroomSnap.exists) {
        return true;
      } else {
        return false;
      }
    } catch (e) {
      // console.error("Upss, ha fallado algo.");
    }
  },

  async addClassRoom(code, data) {
    return new Promise(function(resolve, reject) {
      db.collection("classroms")
        .doc(code)
        .set(data)
        .then(function() {
          resolve();
        })
        .catch(function(error) {
          reject("Error adding classroom: ", error);
        });
    });
  },

  async editClassRoom(data) {
    return new Promise(function(resolve, reject) {
      db.collection("classroms")
        .doc(data.id)
        .set(
          {
            name: data.name,
            max_points: data.max_points,
          },
          { merge: true }
        )
        .then(function() {
          resolve(data);
        })
        .catch(function(error) {
          reject("Error saving classroom: ", error);
        });
    });
  },
  async deleteClassRoom(id) {
    return new Promise(function(resolve, reject) {
      db.collection("classroms")
        .doc(id)
        .delete()
        .then(function() {
          resolve();
        })
        .catch(function(error) {
          reject("Error saving classroom: ", error);
        });
    });
  },

  async getClassRooms(userID) {
    try {
      var classrooms = [];
      //Get Students Data
      var classroomsRef = db
        .collection("classroms")
        .where("user", "==", userID);
      var classroomsSnap = await classroomsRef.get();
      for (const classroomObj of classroomsSnap.docs) {
        //Get Student Data
        var classroom = classroomObj.data();
        classroom.id = classroomObj.id;

        classrooms.push(classroom);
      }

      return classrooms.sort((a, b) =>
        a.name > b.name ? 1 : b.name > a.name ? -1 : 0
      );
    } catch (e) {
      return {
        errorMsg: "Upss, ha fallado algo.",
      };
    }
  },

  async getClassroomStudents(classroomCode) {
    try {
      var students = [];

      //Get Students Data
      var studentsRef = db.collection(
        "classroms/" + classroomCode + "/students"
      );
      var studentsSnap = await studentsRef.get();
      for (const studentObj of studentsSnap.docs) {
        //Get Student Data
        var student = studentObj.data();
        student.id = studentObj.id;

        // Get Student Markers
        student.markers = [];
        var markersSnap = await studentsRef
          .doc(student.id)
          .collection("markers")
          .get();

        for (const markerObj of markersSnap.docs) {
          var marker = markerObj.data();
          marker.id = markerObj.id;
          student.markers.push(marker);
        }
        students.push(student);
      }

      return students;
    } catch (e) {
      return {
        errorMsg: "Upss, ha fallado algo.",
      };
    }
  },
  async getStudent(id, classroomCode) {
    try {
      //Get Students Data
      var studentRef = db
        .collection("classroms/" + classroomCode + "/students")
        .doc(id);
      var studentsSnap = await studentRef.get();

      //Get Student Data
      var student = studentsSnap.data();
      student.id = studentsSnap.id;

      // Get Student Markers
      student.markers = [];
      var markersSnap = await studentRef.collection("markers").get();

      for (const markerObj of markersSnap.docs) {
        var marker = markerObj.data();
        marker.id = markerObj.id;
        student.markers.push(marker);
      }

      return student;
    } catch (e) {
      return {
        errorMsg: "Upss, ha fallado algo.",
      };
    }
  },

  async addClassroomStudent(classroomCode, data) {
    return new Promise(function(resolve, reject) {
      db.collection("classroms/" + classroomCode + "/students")
        .add(data)
        .then(function(docRef) {
          resolve(docRef.id);
        })
        .catch(function(error) {
          reject("Error adding student: ", error);
        });
    });
  },

  async editStudent(classroomCode, data) {
    return new Promise(function(resolve, reject) {
      db.collection("classroms/" + classroomCode + "/students")
        .doc(data.id)
        .set({
          name: data.name,
        })
        .then(() => resolve())
        .catch(() => reject());
    });
  },
  async deleteStudent(classroomCode, id) {
    return new Promise(function(resolve, reject) {
      db.collection("classroms/" + classroomCode + "/students")
        .doc(id)
        .delete()
        .then(function() {
          resolve();
        })
        .catch(function(error) {
          reject("Error saving classroom: ", error);
        });
    });
  },
  async cleanStudent(classroomCode, id) {
    return new Promise(function(resolve, reject) {
      db.collection(
        "classroms/" + classroomCode + "/students/" + id + "/markers"
      )
        .get()
        .then((querySnapshot) => {
          querySnapshot.docs.forEach((snapshot) => {
            snapshot.ref.delete();
          });
          resolve();
        })
        .catch(function(error) {
          reject("Error saving classroom: ", error);
        });
    });
  },
  async cleanStudents(classroomCode) {
    return new Promise(function(resolve, reject) {
      db.collection("classroms/" + classroomCode + "/students/")
        .get()
        .then((querySnapshot) => {
          querySnapshot.docs.forEach((snapshot) => {
            snapshot.ref
              .collection("markers")
              .get()
              .then((querySnapshot) => {
                querySnapshot.docs.forEach((snapshot) => {
                  snapshot.ref.delete();
                });
              });
          });
          resolve();
        })
        .catch(function(error) {
          reject("Error saving classroom: ", error);
        });
    });
  },

  async getMarkers(userID) {
    try {
      var markers = [];
      //Get Students Data
      var markersRef = db.collection("markers").where("user", "==", userID);
      var markersSnap = await markersRef.get();
      for (const markerObj of markersSnap.docs) {
        //Get Student Data
        var marker = markerObj.data();
        marker.id = markerObj.id;

        markers.push(marker);
      }

      return markers;
    } catch (e) {
      return {
        errorMsg: "Upss, ha fallado algo.",
      };
    }
  },

  async addMarker(data) {
    return new Promise(function(resolve, reject) {
      db.collection("markers")
        .add(data)
        .then(function(docRef) {
          resolve(docRef.id);
        })
        .catch(function(error) {
          reject("Error adding marker: ", error);
        });
    });
  },

  async editMarker(data) {
    return new Promise(function(resolve, reject) {
      db.collection("markers")
        .doc(data.id)
        .set({
          title: data.title,
          description: data.description,
          evaluation: data.evaluation,
          image: data.image,
          color: data.color,
          max_points: data.max_points,
          min_points: data.min_points,
          user: data.user,
        })
        .then(function() {
          resolve();
        })
        .catch(function(error) {
          reject("Error saving Marker: ", error);
        });
    });
  },

  async deleteMarker(id) {
    return new Promise(function(resolve, reject) {
      db.collection("markers")
        .doc(id)
        .delete()
        .then(function() {
          resolve();
        })
        .catch(function(error) {
          reject("Error deleting Classroom Marker: ", error);
        });
    });
  },

  async getClassroomMarkers(classroomCode) {
    try {
      var markers = {};

      var markersRef = db.collection("classroms/" + classroomCode + "/markers");
      var markersSnap = await markersRef.get();
      for (const markerObj of markersSnap.docs) {
        var marker = markerObj.data();
        marker.id = markerObj.id;

        //Get Origin Marker Data
        var originMarkerRef = db.collection("markers").doc(marker.id);
        var originMarkerSnap = await originMarkerRef.get();
        if (originMarkerSnap.exists) {
          var originMarker = originMarkerSnap.data();
          marker.title = originMarker.title;
          marker.image = originMarker.image;
          marker.color = originMarker.color;
          markers[marker.id] = marker;
        }
      }
      return markers;
    } catch (e) {
      return {
        errorMsg: "Upss, ha fallado algo.",
      };
    }
  },

  async addClassroomMarker(classroomCode, data) {
    return new Promise(function(resolve, reject) {
      db.collection("classroms/" + classroomCode + "/markers")
        .doc(data.marker.id)
        .set({
          description: data.description,
          evaluation: data.evaluation,
          max_points: data.max_points,
          min_points: data.min_points,
          new: data.new,
          locked: data.locked,
        })
        .then(function() {
          resolve(data.marker.id);
        })
        .catch(function(error) {
          reject("Error adding marker: ", error);
        });
    });
  },

  async editClassroomMarker(classroomCode, data) {
    return new Promise(function(resolve, reject) {
      db.collection("classroms/" + classroomCode + "/markers")
        .doc(data.id)
        .set(
          {
            description: data.description,
            evaluation: data.evaluation,
            max_points: data.max_points,
            min_points: data.min_points,
            new: data.new,
            locked: data.locked,
          },
          { merge: true }
        )
        .then(function() {
          resolve();
        })
        .catch(function(error) {
          reject("Error editing marker: ", error);
        });
    });
  },

  async deleteClassroomMarker(classroomCode, id) {
    return new Promise(function(resolve, reject) {
      db.collection("classroms/" + classroomCode + "/markers")
        .doc(id)
        .delete()
        .then(function() {
          resolve();
        })
        .catch(function(error) {
          reject("Error deleting Classroom Marker: ", error);
        });
    });
  },

  async editStudentMarker(
    classroomCode,
    studentId,
    markerId,
    points,
    locked,
    earned
  ) {
    return new Promise(function(resolve, reject) {
      db.collection(
        "classroms/" + classroomCode + "/students/" + studentId + "/markers"
      )
        .doc(markerId)
        .set({
          points: points,
          locked: locked,
          earned: earned,
        })
        .then(function() {
          resolve();
        })
        .catch(function(error) {
          reject("Error saving student marker points: ", error);
        });
    });
  },
};
