<template>
  <div
    id="item-marker"
    class="item-marker w-1/2 inline-flex relative"
    @click="user.loggedIn && student ? editPoints() : goToMarker()"
    :class="{
      noPoints:
        (!marker.points || marker.points < marker.min_points) && !marker.earned,
    }"
  >
    <div class="container-item-marker bg-white p-4 w-full">
      <div class="flex flex-col items-center absolute">
        <div
          class="color-marker w-5 h-5 rounded-full mb-2"
          :class="marker.color"
        ></div>
        <svg
          style="width: 24px; height: 24px; color: #979700"
          viewBox="0 0 24 24"
          class="mb-1"
          v-if="marker.new"
        >
          <path
            fill="currentColor"
            d="M20,4C21.11,4 22,4.89 22,6V18C22,19.11 21.11,20 20,20H4C2.89,20 2,19.11 2,18V6C2,4.89 2.89,4 4,4H20M8.5,15V9H7.25V12.5L4.75,9H3.5V15H4.75V11.5L7.3,15H8.5M13.5,10.26V9H9.5V15H13.5V13.75H11V12.64H13.5V11.38H11V10.26H13.5M20.5,14V9H19.25V13.5H18.13V10H16.88V13.5H15.75V9H14.5V14A1,1 0 0,0 15.5,15H19.5A1,1 0 0,0 20.5,14Z"
          />
        </svg>

        <svg
          style="width: 24px; height: 24px; color: grey"
          viewBox="0 0 24 24"
          class="mb-1"
          v-if="marker.locked"
        >
          <path
            fill="currentColor"
            d="M10 17C11.1 17 12 16.1 12 15C12 13.9 11.1 13 10 13C8.9 13 8 13.9 8 15S8.9 17 10 17M16 8C17.1 8 18 8.9 18 10V20C18 21.1 17.1 22 16 22H4C2.9 22 2 21.1 2 20V10C2 8.9 2.9 8 4 8H5V6C5 3.2 7.2 1 10 1S15 3.2 15 6V8H16M10 3C8.3 3 7 4.3 7 6V8H13V6C13 4.3 11.7 3 10 3M22 13H20V7H22V13M22 17H20V15H22V17Z"
          />
        </svg>
        <svg
          style="width: 24px; height: 24px; color: #979700"
          viewBox="0 0 24 24"
          class="mb-1"
          v-if="marker.earned"
        >
          <path
            fill="currentColor"
            d="M18.09 11.77L19.56 18.1L14 14.74L8.44 18.1L9.9 11.77L5 7.5L11.47 6.96L14 1L16.53 6.96L23 7.5L18.09 11.77M2 12.43C2.19 12.43 2.38 12.37 2.55 12.26L5.75 10.15L4.18 8.79L1.45 10.59C.989 10.89 .861 11.5 1.16 12C1.36 12.27 1.68 12.43 2 12.43M1.16 21.55C1.36 21.84 1.68 22 2 22C2.19 22 2.38 21.95 2.55 21.84L6.66 19.13L7 17.76L7.31 16.31L1.45 20.16C.989 20.47 .861 21.09 1.16 21.55M1.45 15.38C.989 15.68 .861 16.3 1.16 16.76C1.36 17.06 1.68 17.21 2 17.21C2.19 17.21 2.38 17.16 2.55 17.05L7.97 13.5L8.24 12.31L7.32 11.5L1.45 15.38Z"
          />
        </svg>
      </div>
      <img
        :src="
          'https://firebasestorage.googleapis.com/v0/b/' +
          fb_storage_bucket +
          '/o/' +
          marker.image +
          '?alt=media'
        "
        :alt="marker.title"
        class="image_item-marker mb-3"
      />
      <h3 class="title_item-marker">{{ marker.title }}</h3>
      <div
        class="progress_item-marker inline-flex w-full"
        v-if="marker.hasOwnProperty('points')"
      >
        <progress-bar
          :points="marker.points"
          :maxpoints="marker.max_points"
          :minpoints="marker.min_points"
          class="w-2/3"
        ></progress-bar>
        <div class="poinst_item-marker w-1/3">
          {{ marker.points }} / {{ marker.max_points }}
        </div>
      </div>
      <h4
        class="max-points_item-marker flex justify-center items-center"
        v-else
      >
        Min:<span class="number-max-points mr-4">{{ marker.min_points }}</span>
        Max:
        <span class="number-max-points">{{ marker.max_points }}</span>
      </h4>
    </div>
  </div>
</template>

<script>
import progressBar from "./progressBar";
import { mapState } from "vuex";

export default {
  name: "markerCard",
  props: ["marker", "student"],
  components: { progressBar },
  data() {
    return {
      points: 0,
      fb_storage_bucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
    };
  },
  computed: {
    ...mapState(["user"]),
  },
  methods: {
    goToMarker() {
      this.$router.push({ name: "marker", params: { id: this.marker.id } });
    },

    editPoints() {
      this.$emit("editmarker", this.marker);
    },

    closeModal() {
      this.$modal.hide("editMarker");
    },
  },
};
</script>

<style lang="scss">
#markers-profile {
  display: inline-flex;
  width: 100%;
  padding: 15px;
  flex-wrap: wrap;

  #item-marker,
  .item-marker {
    padding: 0.25rem;
    transform: skew(-5deg);

    .container-item-marker {
      text-align: center;

      > * {
        transform: skew(5deg);
      }

      img,
      .image_item-marker {
        width: 60px;
        display: inline-block;
      }

      h3.title_item-marker {
        font-family: Poppins;
        font-size: 15px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: -0.3px;
        text-align: center;
        color: #ed6362;
      }
    }
  }
}

.progress_item-marker {
  align-items: center;
  margin-top: 7.5px;

  .progress-bar {
    width: 66.666667% !important;
    transform: skew(-10deg);

    .item_progress-bar_lines {
      background: #f0f0f0;
    }
  }

  .poinst_item-marker {
    font-size: 12px;
    font-weight: 600;
    color: #009688;
  }
}
</style>