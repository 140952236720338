<template>
  <div>
    <div class="progress-bar w-full">
      <div class="progress-bar_lines">
        <div
          v-for="n in lines"
          :key="n"
          :class="{ active: n <= nActive }"
          :id="'line-progress-' + n"
          class="item_progress-bar_lines"
          :style="{ width: 'calc(' + lineWidth + '% + 1px)' }"
        ></div>
      </div>
    </div>
    <small v-if="minpoints && points < minpoints" class="faltan">
      Falta{{ minpoints - points > 1 ? "n" : "" }}
      {{ minpoints - points }} punto{{ minpoints - points > 1 ? "s" : "" }}
    </small>
  </div>
</template>

<script>
export default {
  name: "progressBar",
  props: ["maxpoints", "points", "minpoints"],
  computed: {
    progress: function () {
      if (this.minpoints)
        return (100 / (this.maxpoints - this.minpoints)) * this.realPoints;

      return (100 / this.maxpoints) * this.realPoints;
    },

    realPoints: function () {
      if (this.minpoints)
        return this.points - this.minpoints > 0
          ? this.points - this.minpoints
          : 0;
      return this.points;
    },

    nActive: function () {
      let progress = (this.lines * this.progress) / 100;
      return progress.toFixed(0);
    },

    lineWidth: function () {
      return 100 / this.lines;
    },

    lines: function () {
      let windowWidth = window.innerWidth;
      if (windowWidth > 500) return 10;
      else if (windowWidth > 450) return 8;
      return 6;
    },
  },
};
</script>

<style lang="scss">
.progress-bar {
  position: relative;
  display: block;
  width: 100%;
  height: 18px;
  overflow: hidden;

  &:before {
    content: "";
    background: #ced932;
    background: -webkit-linear-gradient(left, #ced932 0%, #009687 100%);
    width: calc(100% - 2px);
    left: 1px;
    height: calc(100% - 2px);
    top: 1px;
    position: absolute;
  }

  .progress-bar_lines {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: inline-flex;

    .item_progress-bar_lines {
      height: 100%;
      margin-left: -1px;
      border-right: 2.5px solid #fff;
      border-left: 2.5px solid #fff;
      background: white;

      &.active {
        background: transparent;
      }
    }
  }
}
.faltan {
  color: #b0a7b6;
  position: absolute;
  left: 0;
  right: 0;
  top: 25px;
  line-height: 10px !important;
  font-size: 12px;
}
</style>
