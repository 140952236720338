<template>
  <vue-pull-refresh :on-refresh="refresh" :config="refreshConfig">
    <div id="user-profile" class="container mx-auto">
      <header id="header-profile" class="header-profile relative">
        <div class="position_user-profile">
          <img
            src="/img/gold-trophy.db83215d.png"
            class="relative w-10px -top-20"
          />
        </div>
        <div class="data_user-profile">
          <h1 class="name-data_user-profile">{{ student.name }}</h1>
          <div class="points-data_user-profile">
            <h4
              class="
                points-markers_student-card
                text-2xl text-purple-900
                font-bold
                text-center
              "
              style="line-height: 18px"
            >
              {{ student.points }}
              <span class="text text-xs block">puntos</span>
            </h4>
          </div>
          <div class="progress-data_user-profile"></div>
        </div>
      </header>
      <div class="crud-settings" v-if="user.loggedIn">
        <div class="item_crud-settings edit" @click="openEditStudent">
          <svg
            viewBox="0 0 24 24"
            height="32"
            width="32"
            role="img"
            aria-hidden="true"
          >
            <path
              fill="#ffffff"
              d="M20.71,7.04C21.1,6.65 21.1,6 20.71,5.63L18.37,3.29C18,2.9 17.35,2.9 16.96,3.29L15.12,5.12L18.87,8.87M3,17.25V21H6.75L17.81,9.93L14.06,6.18L3,17.25Z"
            />
          </svg>
        </div>
        <div class="item_crud-settings" @click="qduplicate">
          <svg
            viewBox="0 0 24 24"
            height="32"
            width="32"
            role="img"
            aria-hidden="true"
          >
            <path
              fill="#ffffff"
              d="M11,17H4A2,2 0 0,1 2,15V3A2,2 0 0,1 4,1H16V3H4V15H11V13L15,16L11,19V17M19,21V7H8V13H6V7A2,2 0 0,1 8,5H19A2,2 0 0,1 21,7V21A2,2 0 0,1 19,23H8A2,2 0 0,1 6,21V19H8V21H19Z"
            />
          </svg>
        </div>
        <div class="item_crud-settings" @click="qclean">
          <svg viewBox="0 0 1000 1000" height="32" width="32">
            <g>
              <path
                fill="#ffffff"
                d="M599,755.5c-5.6,22.3-34.1,71.1-79.5,136.9c12.4,2.9,24.3,6.1,36.4,8.3c22,4,44.1,7.1,66.1,11.1c7.2,1.3,10.9-1.3,14.7-7c44.2-65.8,79.9-136.1,109.8-209.3c11.7-28.7,21.7-58,32.5-87c1.1-3,2.2-5.9,3.5-9.5c23.7,8.2,46.9,16.2,71.1,24.5c-4.3,12.7-8.3,24.8-12.6,36.7c-36.4,101.1-81.7,197.8-142.1,287c-5,7.4-10.7,14.4-15.6,21.8c-11.4,17.1-27.3,22.4-47.2,20.5c-76.8-7.3-151.3-24.8-223.4-51.9c-131.2-49.4-244.2-125-333.7-234c-10.7-13.1-13.8-27.5-6.8-42.9c6.8-14.8,19.8-21.5,35.6-21.7c68.2-0.9,133.9-14.6,197.5-38.6c57.5-21.7,109.9-51.9,150.2-99.5c11.7-13.8,21.2-29.4,32.3-45c20.8,11.7,42.2,23.8,65.7,37c-12.2,17.7-23,35.3-35.8,51.4c-31.2,39.5-70.5,69.4-114.2,93.7c-65.8,36.6-136.9,57.7-210.9,69.7c-1.5,0.2-2.9,0.5-6.5,1.2c10.6,9.7,19.5,18.8,29.3,26.7c18.4,14.9,37.1,29.4,56.2,43.4c3.7,2.7,10.2,3.9,14.8,2.8c38-8.6,72.8-23.8,102-50.8c-2.5,13.3-26.5,47-60.2,84.6c21.2,10.5,42.5,19.8,62.5,31.5c25.8,15.2,50.5,11,75.8,0.1c42.8-18.5,79.5-46.1,114.6-76.2C586.8,766.4,592.3,761.4,599,755.5L599,755.5z M876.3,560.4C756.2,507,637,453.9,516.9,400.5c9.9-23.1,25.5-40.6,46.6-53c35.2-20.7,72-23.1,109.3-6.8c48,20.9,95.9,42,143.5,64c55.8,25.9,82.1,84,65.1,141.7C880,550.9,878.2,555.2,876.3,560.4L876.3,560.4z M823,351.1c-37.8-16.8-75.2-33.5-112.5-50.1c-0.8-0.4-1.4-1.2-2.4-2.2c1.4-2.8,2.7-5.7,4.2-8.4c46.7-87.7,93.5-175.3,140.3-263c7.8-14.7,17-19.1,33.8-16.6c15.5,2.3,28.6,8.9,38.7,21c6.6,8,8.3,16.7,4.6,26.8c-34.9,96-69.6,192.1-104.3,288.2C824.8,347.9,824.1,348.9,823,351.1L823,351.1z"
              />
            </g>
          </svg>
        </div>
        <div class="item_crud-settings" @click="qdelete">
          <svg
            viewBox="0 0 24 24"
            height="32"
            width="32"
            role="img"
            aria-hidden="true"
          >
            <path
              fill="currentColor"
              d="M19,4H15.5L14.5,3H9.5L8.5,4H5V6H19M6,19A2,2 0 0,0 8,21H16A2,2 0 0,0 18,19V7H6V19Z"
            />
          </svg>
        </div>
      </div>
      <main
        id="markers-profile"
        class="list-markers flex-wrap"
        v-if="markersStudent"
      >
        <marker-card
          v-for="marker in markersStudent"
          :key="marker.id + marker.points"
          :marker="marker"
          :student="student"
          @editmarker="openEditMarker"
        ></marker-card>
      </main>
      <modal
        name="editMarker"
        :clickToClose="false"
        height="auto"
        class="form-modal"
        :scrollable="true"
      >
        <h1 class="title-modal">Edit User Marker</h1>
        <div
          id="markers-profile"
          class="flex justify-center markers-profile-modal"
        >
          <marker-card :marker="markerForm.marker"></marker-card>
        </div>
        <div class="input-modal text-center">
          <span class="label">Puntuación:</span>
          <number-input v-model="markerForm.points" inline controls
            ><span class="label">Puntuación Marker:</span>
          </number-input>
        </div>

        <div
          class="flex justify-center items-center py-3"
          @click="!userCan.lockBadges ? $modal.show('goPremium') : null"
        >
          <input
            type="checkbox"
            v-model="markerForm.locked"
            :value="true"
            style="width: 20px"
            :disabled="!userCan.lockBadges"
          />
          <div>Bloqueada</div>
        </div>
        <div
          class="flex justify-center items-center py-3"
          @click="!userCan.markEarnedBadges ? $modal.show('goPremium') : null"
        >
          <input
            type="checkbox"
            v-model="markerForm.earned"
            :value="true"
            style="width: 20px"
            :disabled="!userCan.markEarnedBadges"
          />
          <div>Conseguida</div>
        </div>

        <button @click="savePointsMarker()" class="w-1-2 save-modal">
          <span class="text-button">Guardar</span>
        </button>
        <button @click="closeModal" class="w-1-2 close-modal">
          <span class="text-button">Cancelar</span>
        </button>
      </modal>
      <modal
        name="editStudent"
        :clickToClose="false"
        height="auto"
        class="form-modal"
        :scrollable="true"
      >
        <h1 class="title-modal">Editar Alumno</h1>
        <input
          type="text"
          v-model="editStudentForm.name"
          placeholder="Introduce el nombre del alumno/a"
        />
        <button @click="editStudent" class="w-1-2 save-modal">
          <span class="text-button">Guardar</span>
        </button>
        <button @click="closeModal" class="w-1-2 close-modal">
          <span class="text-button">Cancelar</span>
        </button>
      </modal>
    </div>
  </vue-pull-refresh>
</template>

<script>
import markerCard from "../components/markerCard";
import { mapGetters, mapState } from "vuex";
import data from "../services/data";
import helpers from "../services/helpers";
import VuePullRefresh from "vue-awesome-pull-refresh";

export default {
  name: "Student",
  components: { markerCard, VuePullRefresh },
  data() {
    return {
      markerForm: {
        id: null,
        points: 10,
        locked: false,
        earned: false,
        marker: null,
      },
      editStudentForm: {
        id: null,
        name: null,
      },
      refreshConfig: {
        errorLabel: "Ha habido un error",
        startLabel: "Actualizar datos",
        readyLabel: "Suelta para actualizar",
        loadingLabel: "Actualizando datos",
        pullDownHeight: 40,
      },
    };
  },
  computed: {
    student() {
      return this.$store.getters.student(this.$route.params.id);
    },
    markersStudent() {
      return this.$store.getters.markersStudent(this.$route.params.id);
    },
    ...mapState(["markers", "user", "classroomCode", "classroomStudents"]),
    ...mapGetters(["userCan"]),
  },
  methods: {
    openEditMarker(marker) {
      this.markerForm.marker = marker;
      this.markerForm.id = marker.id;
      this.markerForm.points = marker.points;
      this.markerForm.locked =
        marker.locked !== undefined ? marker.locked : false;
      this.markerForm.earned =
        marker.earned !== undefined ? marker.earned : false;
      this.$modal.show("editMarker");
    },
    openEditStudent() {
      this.editStudentForm.id = this.student.id;
      this.editStudentForm.name = this.student.name;
      this.$modal.show("editStudent");
    },
    closeModal() {
      this.$modal.hide("editMarker");
      this.$modal.hide("editStudent");
    },
    editStudent() {
      data.editStudent(this.classroomCode, this.editStudentForm).then(() => {
        this.student.name = this.editStudentForm.name;
        this.getClassroomStudents();
        this.closeModal();
      });
    },
    qdelete() {
      this.$dialog
        .confirm("¿Estas seguro que quieres eliminar el alumno/a?")
        .then(() => {
          this.deleteStudent();
        })
        .catch(function () {});
    },
    qclean() {
      if (this.userCan.clearData)
        this.$dialog
          .confirm("¿Estas seguro que quieres vaciar los datos del alumno/a?")
          .then(() => {
            this.cleanStudent();
          })
          .catch(function () {});
      else this.$modal.show("goPremium");
    },
    qduplicate() {
      if (
        this.userCan.copyData &&
        this.userCan.maxStudentsByClassroom > this.classroomStudents.length
      )
        this.$dialog
          .confirm("¿Estas seguro que quieres duplicar el alumno/a?")
          .then(() => {
            this.duplicateStudent();
          })
          .catch(function () {});
      else this.$modal.show("goPremium");
    },
    duplicateStudent() {
      this.$store
        .dispatch("duplicateStudent", this.student)
        .then((newStudentID) => {
          this.getClassroomStudents(this.classroomCode);
          this.$router.push({
            name: "student",
            params: { id: newStudentID },
          });
        });
    },

    deleteStudent() {
      data
        .deleteStudent(this.classroomCode, this.student.id)
        .then(() => {
          data.getClassroomStudents(this.classroomCode).then((response) => {
            if (response && response.errorMsg) {
              this.$router.push({ name: "access" });
            } else {
              this.$store.commit(
                "setClassroomStudents",
                helpers.addTotalPointsToStudents(response)
              );
              this.$router.push({ name: "ranking" });
            }
          });
        })
        .catch(function () {
          //console.error(error)
        });
    },
    cleanStudent() {
      data
        .cleanStudent(this.classroomCode, this.student.id)
        .then(() => {
          data.getClassroomStudents(this.classroomCode).then((response) => {
            if (response && response.errorMsg) {
              this.$router.push({ name: "access" });
            } else {
              this.$store.commit(
                "setClassroomStudents",
                helpers.addTotalPointsToStudents(response)
              );
            }
          });
        })
        .catch(function () {
          //console.error(error)
        });
    },
    savePointsMarker() {
      data
        .editStudentMarker(
          this.classroomCode,
          this.student.id,
          this.markerForm.id,
          this.markerForm.points,
          this.markerForm.locked,
          this.markerForm.earned
        )
        .then(() => {
          this.getClassroomStudents();
          this.closeModal();
        })
        .catch(() => {});
    },
    getClassroomStudents() {
      data.getClassroomStudents(this.classroomCode).then((response) => {
        if (response && response.errorMsg) {
          this.$router.push({ name: "access" });
        } else {
          this.refresh();
        }
      });
    },
    refresh() {
      return new Promise((resolve, reject) => {
        data.getClassroomStudents(this.classroomCode).then((response) => {
          if (response && response.errorMsg) {
            reject();
          } else {
            this.$store.commit(
              "setClassroomStudents",
              helpers.addTotalPointsToStudents(response)
            );
            data.getClassroomMarkers(this.classroomCode).then((response) => {
              if (response && response.errorMsg) {
                reject();
              } else {
                this.$store.commit("setClassroomMarkers", response);
                resolve();
              }
            });
          }
        });
      });
    },
  },
  created() {
    if (!this.student) this.$router.push({ name: "ranking" });
  },
};
</script>

<style lang="scss">
#user-profile {
  #crud {
    .item_crud-settings {
      border: 3px solid #ed6362;
      background: #ed6362;
    }
  }

  #markers-profile {
    .noPoints {
      filter: grayscale(0.9);
      opacity: 0.7;
    }
  }
}
</style>
