<template>
  <modal
    name="goPremium"
    :clickToClose="false"
    height="auto"
    class="form-modal"
    :scrollable="true"
  >
    <h1 class="title-modal">Pásate a Premium</h1>
    <p class="text-center">Esta acción no está disponible en el "Plan Free".</p>
    <p class="text-center">
      Activa "Plan Premium Trimestre" o "Plan Premium Anual" para crear sin
      limitaciones.
    </p>
    <p class="text-center font-bold py-3">
      ¡Te regalamos el primer periodo Premium!
    </p>
    <button class="w-1-2 close-modal" @click="closeModal">
      <span class="text-button">Cancelar</span>
    </button>

    <button class="w-1-2" @click="submit">Elegir Plan</button>
    <premium-modal></premium-modal>
  </modal>
</template>

<script>
import { mapState } from "vuex";
//import data from "../services/data";
import premiumModal from "../components/goPremiumProfile.vue";
export default {
  components: { premiumModal },
  data() {
    this.publishableKey = process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY;
    return {
      loading: false,
    };
  },
  computed: {
    ...mapState(["user"]),
  },
  methods: {
    submit() {
      // You will be redirected to Stripe's secure checkout page
      //data.startSubscription(this.user.data.id).then(() => {
      // console.log("StartSubscription");
      // });
      this.$modal.show("goPremiumProfile");
    },
    closeModal() {
      this.$modal.hide("goPremium");
    },
  },
};
</script>

<style>
</style>