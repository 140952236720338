<template>
  <div class="container mx-auto originMarker-view" v-if="marker">
    <header
      id="header-marker"
      class="header-markertext-center flex flex-col items-center pt-10 pb-5 relative"
    >
      <div class="icon_marker">
        <img
          :src="
            'https://firebasestorage.googleapis.com/v0/b/' +
            fb_storage_bucket +
            '/o/' +
            marker.image +
            '?alt=media'
          "
          class="relative w-10px -top-20"
          :alt="marker.title"
        />
      </div>
      <div
        class="color-marker w-5 h-5 rounded-full mb-3"
        :class="marker.color"
      ></div>
      <div class="data_marker">
        <h1 class="title-data_marker">{{ marker.title }}</h1>
        <p class="description-data_marker whitespace-pre-wrap">
          {{ marker.description }}
        </p>
        <h2 class="mt-5 title-evaluation" v-if="marker.evaluation.length">
          Evaluación
        </h2>
        <p
          class="description-data_marker whitespace-pre-wrap"
          v-if="marker.evaluation.length"
        >
          {{ marker.evaluation }}
        </p>
        <div class="flex justify-center">
          <div class="mx-4">
            <h2 class="mt-5 title-evaluation">Mínimo</h2>
            <p class="description-data_marker whitespace-pre-wrap">
              {{ marker.min_points }} puntos
            </p>
          </div>
          <div class="mx-4">
            <h2 class="mt-5 title-evaluation">Máximo</h2>
            <p class="description-data_marker whitespace-pre-wrap">
              {{ marker.max_points }} puntos
            </p>
          </div>
        </div>
      </div>
    </header>
    <div class="crud-settings" style="top: 35px" v-if="user.loggedIn">
      <div class="item_crud-settings edit" @click="editMarker()">
        <svg
          viewBox="0 0 24 24"
          height="32"
          width="32"
          role="img"
          aria-hidden="true"
        >
          <path
            fill="#ffffff"
            d="M20.71,7.04C21.1,6.65 21.1,6 20.71,5.63L18.37,3.29C18,2.9 17.35,2.9 16.96,3.29L15.12,5.12L18.87,8.87M3,17.25V21H6.75L17.81,9.93L14.06,6.18L3,17.25Z"
          />
        </svg>
      </div>
      <div class="item_crud-settings" @click="qduplicate()">
        <svg
          viewBox="0 0 24 24"
          height="32"
          width="32"
          role="img"
          aria-hidden="true"
        >
          <path
            fill="#ffffff"
            d="M11,17H4A2,2 0 0,1 2,15V3A2,2 0 0,1 4,1H16V3H4V15H11V13L15,16L11,19V17M19,21V7H8V13H6V7A2,2 0 0,1 8,5H19A2,2 0 0,1 21,7V21A2,2 0 0,1 19,23H8A2,2 0 0,1 6,21V19H8V21H19Z"
          />
        </svg>
      </div>
      <div class="item_crud-settings edit" @click="qdelete()">
        <svg
          viewBox="0 0 24 24"
          height="32"
          width="32"
          role="img"
          aria-hidden="true"
        >
          <path
            fill="currentColor"
            d="M19,4H15.5L14.5,3H9.5L8.5,4H5V6H19M6,19A2,2 0 0,0 8,21H16A2,2 0 0,0 18,19V7H6V19Z"
          />
        </svg>
      </div>
    </div>
    <edit-modal :marker="marker"></edit-modal>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import editModal from "../components/originMarkerForm";
import data from "../services/data";

export default {
  name: "originMarker",
  components: { editModal },
  data() {
    return {
      markerForm: {
        description: "",
        evaluation: "",
        max_points: 1,
        min_points: 1,
      },
      fb_storage_bucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
    };
  },
  computed: {
    marker() {
      return this.$store.getters.originMarker(this.$route.params.id);
    },
    ...mapState(["user", "markers"]),
    ...mapGetters(["userCan"]),
  },
  methods: {
    editMarker() {
      this.$modal.show("originMarkerForm");
    },
    qduplicate() {
      if (this.userCan.maxMarkers > this.markers.length)
        this.$dialog
          .confirm("¿Estas seguro que quieres duplicar la Insignia?")
          .then(() => {
            this.duplicateOriginMarker();
          })
          .catch(function () {});
      else this.$modal.show("goPremium");
    },
    duplicateOriginMarker() {
      let newMarker = Object.assign({}, this.marker);

      if (!newMarker.title.includes("(Copia)")) newMarker.title += " (Copia)";
      data.addMarker(newMarker).then((markerid) => {
        data.getMarkers(this.user.data.id).then((response) => {
          if (response && response.errorMsg) {
            this.$router.push({ name: "access" });
          } else {
            this.$store.commit("setMarkers", response);
            this.$router.push({
              name: "originmarker",
              params: { id: markerid },
            });
          }
        });
      });
    },
    qdelete() {
      this.$dialog
        .confirm(
          "¿Estas seguro que quieres eliminar la insignia? Ten en cuenta que si se está usando en alguna clase, desaparecerán los datos por completo"
        )
        .then(() => {
          this.deleteMarker();
        })
        .catch(function () {});
    },
    deleteMarker() {
      data
        .deleteMarker(this.marker.id)
        .then(() => {
          data.getMarkers(this.user.data.id).then((response) => {
            if (response && response.errorMsg) {
              this.$router.push({ name: "access" });
            } else {
              this.$store.commit("setMarkers", response);
              this.$router.push({
                name: "originmarkers",
              });
            }
          });
        })
        .catch(() => {});
    },
  },
};
</script>
<style lang="scss">
.originMarker-view {
  header#header-marker {
    width: 100%;
    height: auto;
    background-image: linear-gradient(to bottom, #173e3b, #009688);

    .icon_marker {
      img {
        width: 120px;
        display: block;
        margin: 0 auto 10px auto;
      }
    }

    .data_marker {
      width: 100%;
      text-align: center;

      h1,
      .title-data_marker {
        font-size: 30px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: normal;
        color: #ffffff;
        padding: 0 25px;
      }

      .description-data_marker {
        font-size: 11px;
        padding: 0 25px;
      }

      h2,
      .title-evaluation {
        font-size: 16px;
        font-weight: 500;
      }
    }
  }
}
</style>