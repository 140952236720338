<template>
  <vue-pull-refresh :on-refresh="refresh" :config="refreshConfig">
    <div id="list-markers" class="container mx-auto">
      <header
        id="header-section"
        class="header-ranking inline-block w-full mt-12 mb-1 text-center relative"
      >
        <h1 class="text-white text-center text-3xl font-bold">Insignias</h1>
        <h2
          class="text-white text-center text-2xl font-thin uppercase tracking-widest"
        >
          {{ classroom.name }}
        </h2>
        <span class="points-max-classroom"
          >Puntos Clase: {{ classroom.max_points }}</span
        >
        <div class="crud-settings">
          <!--<div class="item_crud-settings edit" @click="editStudent()">-->
          <!--<svg viewBox="0 0 24 24" height="32" width="32" role="img" aria-hidden="true">-->
          <!--<path fill="#ffffff" d="M20.71,7.04C21.1,6.65 21.1,6 20.71,5.63L18.37,3.29C18,2.9 17.35,2.9 16.96,3.29L15.12,5.12L18.87,8.87M3,17.25V21H6.75L17.81,9.93L14.06,6.18L3,17.25Z" />-->
          <!--</svg>-->
          <!--</div>-->
          <div class="item_crud-settings" @click="openOrderOptions">
            <svg
              viewBox="0 0 24 24"
              height="32"
              width="32"
              role="img"
              aria-hidden="true"
            >
              <path
                fill="currentColor"
                d="M19 17H22L18 21L14 17H17V3H19M11 13V15L7.67 19H11V21H5V19L8.33 15H5V13M9 3H7C5.9 3 5 3.9 5 5V11H7V9H9V11H11V5C11 3.9 10.11 3 9 3M9 7H7V5H9Z"
              />
            </svg>
          </div>
          <div
            class="item_crud-settings add"
            @click="openAddMarker()"
            v-if="user.loggedIn && avaiableMarkersToAdd.length"
          >
            <svg
              viewBox="0 0 24 24"
              height="32"
              width="32"
              role="img"
              aria-hidden="true"
            >
              <path
                fill="#ffffff"
                d="M17,3A2,2 0 0,1 19,5V21L12,18L5,21V5C5,3.89 5.9,3 7,3H17M11,7V9H9V11H11V13H13V11H15V9H13V7H11Z"
              />
            </svg>
          </div>
        </div>
      </header>

      <transition-group
        id="markers-profile"
        class="list-markers flex-wrap"
        name="list"
        tag="main"
        v-on:enter="enter"
        v-on:before-enter="beforeEnter"
      >
        <marker-card
          v-for="marker in orderedClassroomMarkers"
          :key="marker.id"
          :marker="marker"
          :markers="classroomMarkers"
        ></marker-card>
      </transition-group>

      <order-modal
        :options="orderOptions"
        name="orderModal"
        @changeOrder="changeOrder"
      ></order-modal>
      <edit-modal></edit-modal>
    </div>
  </vue-pull-refresh>
</template>

<script>
import markerCard from "../components/markerCard";
import orderModal from "../components/orderModal";
import Velocity from "velocity-animate";
import { mapState } from "vuex";
import data from "../services/data";
import VuePullRefresh from "vue-awesome-pull-refresh";
import OrderModal from "../components/orderModal.vue";
import editModal from "../components/markerForm";

export default {
  name: "list-markers",
  components: {
    markerCard,
    VuePullRefresh,
    orderModal,
    OrderModal,
    editModal,
  },

  data() {
    return {
      refreshConfig: {
        errorLabel: "Ha habido un error",
        startLabel: "Actualizar datos",
        readyLabel: "Suelta para actualizar",
        loadingLabel: "Actualizando datos",
        pullDownHeight: 40,
      },
      orderOptions: [
        { name: "Alfabeticamente", value: "title", order: "asc" },
        { name: "Color", value: "color", order: "asc" },
        { name: "Puntuación Ascendente", value: "max_points", order: "asc" },
        { name: "Puntuación Descendente", value: "max_points", order: "desc" },
      ],
      orderBy: { name: "Alfabeticamente", value: "title", order: "asc" },
    };
  },
  computed: {
    ...mapState([
      "classroomMarkers",
      "classroom",
      "classroomCode",
      "markers",
      "user",
    ]),
    orderedClassroomMarkers() {
      let classroomMarkers = Object.values(this.classroomMarkers);

      let order = this.orderBy.order === "asc" ? [1, -1] : [-1, 1];

      return classroomMarkers.sort((a, b) =>
        a[this.orderBy.value] > b[this.orderBy.value]
          ? order[0]
          : b[this.orderBy.value] > a[this.orderBy.value]
          ? order[1]
          : 0
      );
    },
    avaiableMarkersToAdd() {
      if (!this.user.loggedIn) return [];

      return this.markers.filter((marker) => {
        return !this.classroomMarkers.hasOwnProperty(marker.id);
      });
    },
  },
  methods: {
    changeOrder(value) {
      this.orderBy = value;
    },
    beforeEnter: function (el) {
      el.style.opacity = 0;
      el.style.transform = "translate-y(15px)";
    },
    enter: function (el) {
      var delay = el.dataset.index * 100;
      setTimeout(function () {
        Velocity(
          el,
          {
            opacity: 1,
            translateY: 0,
            skew: "-5deg",
          },
          "easeOut"
        );
      }, delay);
    },
    openAddMarker() {
      this.$modal.show("markerForm");
    },
    openOrderOptions() {
      this.$modal.show("orderModal");
    },

    getClassroomMarkers() {
      data.getClassroomMarkers(this.classroomCode).then((response) => {
        if (response && response.errorMsg) {
          this.$router.push({ name: "access" });
        } else {
          this.$store.commit("setClassroomMarkers", response);
        }
      });
    },
    getOriginMarkers() {
      data.getMarkers(this.user.data.id).then((response) => {
        if (response && response.errorMsg) {
          this.$router.push({ name: "access" });
        } else {
          this.$store.commit("setMarkers", response);
        }
      });
    },
    refresh() {
      return new Promise((resolve, reject) => {
        data.getClassroomMarkers(this.classroomCode).then((response) => {
          if (response && response.errorMsg) {
            reject();
          } else {
            this.$store.commit("setClassroomMarkers", response);
            resolve();
          }
        });
      });
    },
  },
  created() {
    if (this.user.loggedIn && !this.markers.length) this.getOriginMarkers();
    this.getClassroomMarkers();
  },
};
</script>

<style lang="scss">
#markers-profile {
  #item-marker {
    padding: 0.25rem;
    -webkit-transform: skew(-5deg) translateY(0px) !important;
    transform: skew(-5deg) translateY(0px) !important;

    h3.title_item-marker {
      font-size: 19px;
      margin-bottom: 20px;
    }

    h4.max-points_item-marker {
      font-size: 12px;
      position: absolute;
      bottom: 4px;
      left: 2px;
      transform: skew(0deg);
      width: calc(100% + -4px);
      background: #009688;

      span.number-max-points {
        display: inline-block;
        font-size: 19px;
        margin-left: 6px;
        font-weight: 600;
      }
    }
  }
}

span.points-max-classroom {
  color: #ffdb70;
  font-size: 16px;
  font-weight: 600;
  position: relative;
  padding-top: 10px;
  margin-top: 10px;
  display: block;
  text-transform: capitalize;

  &:before {
    content: " ";
    position: absolute;
    top: 0;
    left: calc(50% - 17px);
    width: 34px;
    height: 1px;
    background: #d3bc5b;
  }
}

.list-enter-active,
.list-leave-active {
  transition: all 1s;
}

.list-enter, .list-leave-to /* .list-leave-active below version 2.1.8 */ {
  opacity: 0;
  transform: translateY(30px);
}
</style>
