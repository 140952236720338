<template>
  <modal
    name="markerForm"
    :clickToClose="false"
    height="auto"
    class="form-modal"
    v-if="user.loggedIn"
    :scrollable="true"
  >
    <h1
      class="title-modal"
      v-text="marker ? 'Editar Insignia' : 'Añadir Insignia'"
    ></h1>

    <multiselect
      v-if="!marker"
      v-model="markerForm.marker"
      placeholder="Elige una Insignia Global"
      select-label="Pulsa enter para seleccionar"
      deselect-label="Pulsa enter para eliminar"
      selected-label="Seleccionada"
      track-by="id"
      label="title"
      :options="avaiableMarkersToAdd"
      class="mb-4"
    >
    </multiselect>

    <div class="input-modal text-center">
      <span class="label">Descripción:</span>
      <textarea-autosize
        :min-height="50"
        v-model="markerForm.description"
        placeholder="Introduce una descripción"
      />
    </div>

    <div class="input-modal text-center">
      <span class="label">Evaluación:</span>
      <textarea-autosize
        :min-height="50"
        v-model="markerForm.evaluation"
        placeholder="Evaluación para conseguir la insignia"
      />
    </div>

    <div class="input-modal text-center">
      <span class="label">Puntuación Máxima:</span>
      <number-input v-model="markerForm.max_points" :min="1" inline controls
        ><span class="label">Puntuación:</span>
      </number-input>
    </div>
    <div class="input-modal text-center">
      <span class="label">Puntuación Mínima:</span>
      <number-input v-model="markerForm.min_points" :min="1" inline controls
        ><span class="label">Puntuación Mínima:</span>
      </number-input>
    </div>
    <div
      class="flex justify-center items-center py-3"
      @click="!userCan.markNewBadges ? $modal.show('goPremium') : null"
    >
      <input
        type="checkbox"
        v-model="markerForm.new"
        :value="true"
        style="width: 20px"
        :disabled="!userCan.markNewBadges"
      />
      <div>Nueva</div>
    </div>
    <div
      class="flex justify-center items-center py-3"
      @click="!userCan.lockBadges ? $modal.show('goPremium') : null"
    >
      <input
        type="checkbox"
        v-model="markerForm.locked"
        :value="true"
        style="width: 20px"
        :disabled="!userCan.lockBadges"
      />
      <div>Bloqueada</div>
    </div>

    <button @click="addMarker" class="w-1-2 save-modal" v-if="!markerForm.id">
      <span class="text-button">Añadir</span>
    </button>
    <button @click="updateMarker" class="w-1-2 save-modal" v-else>
      <span class="text-button">Guardar</span>
    </button>
    <button @click="closeModal" class="w-1-2 close-modal">
      <span class="text-button">Cancelar</span>
    </button>
  </modal>
</template>

<script>
import NumberInput from "@chenfengyuan/vue-number-input";
import data from "../services/data";
import { mapState, mapGetters } from "vuex";
import Multiselect from "vue-multiselect";

export default {
  name: "markerForm",
  components: { NumberInput, Multiselect },
  props: ["marker"],
  data() {
    return {
      markerForm: {
        marker: null,
        description: "",
        evaluation: "",
        max_points: 10,
        min_points: 1,
        locked: false,
        new: false,
      },
      fb_storage_bucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
    };
  },
  computed: {
    ...mapState(["user", "classroomCode", "markers", "classroomMarkers"]),
    ...mapGetters(["userCan"]),
    avaiableMarkersToAdd() {
      if (!this.user.loggedIn) return [];

      return this.markers.filter((marker) => {
        return !this.classroomMarkers.hasOwnProperty(marker.id);
      });
    },
  },
  methods: {
    addMarker() {
      this.markerForm.user = this.user.data.id;
      this.markerForm.locked =
        this.markerForm.locked !== undefined ? this.markerForm.locked : false;
      this.markerForm.new =
        this.markerForm.new !== undefined ? this.markerForm.new : false;
      data
        .addClassroomMarker(this.classroomCode, this.markerForm)
        .then((markerid) => {
          data.getClassroomMarkers(this.classroomCode).then((response) => {
            if (response && response.errorMsg) {
              this.$router.push({ name: "access" });
            } else {
              this.$store.commit("setClassroomMarkers", response);
              this.closeModal();
              this.$router.push({
                name: "marker",
                params: { id: markerid },
              });
            }
          });
        });
    },
    updateMarker() {
      data.editClassroomMarker(this.classroomCode, this.markerForm).then(() => {
        data.getClassroomMarkers(this.classroomCode).then((response) => {
          if (response && response.errorMsg) {
            this.$router.push({ name: "access" });
          } else {
            this.$store.commit("setClassroomMarkers", response);
            this.closeModal();
          }
        });
      });
    },
    updateForm() {
      this.markerForm.description = this.marker.description;
      this.markerForm.evaluation = this.marker.evaluation;
      this.markerForm.max_points = this.marker.max_points;
      this.markerForm.min_points = this.marker.min_points;
      this.markerForm.locked =
        this.marker.locked !== undefined ? this.marker.locked : false;
      this.markerForm.new =
        this.marker.new !== undefined ? this.marker.new : false;
      this.markerForm.id = this.marker.id;
      this.markerForm.user = this.user.data.id;
    },

    closeModal() {
      this.$modal.hide("markerForm");
    },
  },
  created() {
    if (this.marker) {
      this.updateForm();
    }
  },
  watch: {
    marker: function () {
      this.updateForm();
    },

    "markerForm.marker": function (value) {
      if (!this.marker)
        if (value) {
          this.markerForm.description = value.description;
          this.markerForm.evaluation = value.evaluation;
          this.markerForm.max_points = value.max_points;
          this.markerForm.min_points = value.min_points;
          this.markerForm.locked = value.locked;
          this.markerForm.new = value.new;
        } else {
          this.markerForm.marker = null;
          this.markerForm.description = "";
          this.markerForm.evaluation = "";
          this.markerForm.max_points = 10;
          this.markerForm.min_points = 1;
          this.markerForm.locked = false;
          this.markerForm.new = true;
        }
    },
  },
};
</script>

<style>
</style>