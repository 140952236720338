<template>
  <div id="app" class="app-my-badge-marker">
    <div class="container-app">
      <vue-page-transition name="fade-in-down">
        <router-view />
      </vue-page-transition>

      <menu-app></menu-app>
      <go-premium></go-premium>
    </div>
    <apple-add-to-home-screen-modal
      v-if="showAddToHomeScreenModalForApple"
      class="apple-add-to-home-screen-modal"
      @close="closeAddToHomeScreenModalForApple(false)"
    >
    </apple-add-to-home-screen-modal>
    <div class="overlay-app"></div>
  </div>
</template>

<script>
import menuApp from "./components/menuApp";
import AppleAddToHomeScreenModal from "./components/AppleAddToHomeScreenModal";
import { mapState, mapActions } from "vuex";
import goPremium from "./components/goPremium";

export default {
  name: "app",
  components: { menuApp, AppleAddToHomeScreenModal, goPremium },
  computed: {
    ...mapState(["showAddToHomeScreenModalForApple"]),
  },
  methods: mapActions(["closeAddToHomeScreenModalForApple"]),
};
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700,800,900&display=swap");
@import "assets/css/app";

.apple-add-to-home-screen-modal {
  position: absolute;
  bottom: 0;
  right: 0;
  top: 0;
  left: 0;
  height: fit-content;
  width: fit-content;
  margin: auto;
  z-index: 1000;
}
</style>
