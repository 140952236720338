<template>
  <div id="menu-app">
    <!-- Admin Bar Class -->
    <div
      v-if="user.loggedIn && !adminRoute"
      class="fixed w-full font-bold italic admin-bar flex slideTop"
    >
      <h3 class="nameclass_admin-bar w-2/3">
        <span>CLASE :</span> {{ classroom.name }}
      </h3>
      <span class="idclass_admin-bar w-1/3 text-right">{{ classroom.id }}</span>
    </div>

    <!-- Menú APP -->
    <div
      class="menu-app main-menu flex fixed"
      v-if="route !== 'access'"
      :class="{ admin: adminRoute, 'admin_menu-app': user.loggedIn }"
    >
      <div
        class="button_menu-app button-markers inline-flex w-1/3 text-center m-auto"
        :class="{ active: (route === 'originmarkers') | (route === 'markers') }"
        @click="goToDirection(adminRoute ? 'originmarkers' : 'markers')"
      >
        <svg
          v-if="adminRoute"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          version="1.1"
          width="24"
          height="24"
          viewBox="0 0 24 24"
        >
          <path
            d="M6.2,13C6.6,14.8 7.6,16.3 10,16.8V19.34C8,20.12 8,22 8,22H13.2L10.2,15L14.2,10L10.7,4H8C8,3 6.9,2 6,2H2V11C2,12 3,13 4,13H6.2M6,11H4V4H6V11M17.8,13H20C21,13 22,12 22,11V2H18C17.1,2 16,3 16,4H13.9L16.7,10L12.2,15L13.2,22H16C16,22 16,20.12 14,19.34L14,16.85C16.39,16.35 17.5,14.7 17.8,13M20,11H18V4H20V11Z"
          />
        </svg>
        <svg v-else style="width: 24px; height: 24px" viewBox="0 0 24 24">
          <path
            fill="#ffffff"
            d="M20.2,2H19.5H18C17.1,2 16,3 16,4H8C8,3 6.9,2 6,2H4.5H3.8H2V11C2,12 3,13 4,13H6.2C6.6,15 7.9,16.7 11,17V19.1C8.8,19.3 8,20.4 8,21.7V22H16V21.7C16,20.4 15.2,19.3 13,19.1V17C16.1,16.7 17.4,15 17.8,13H20C21,13 22,12 22,11V2H20.2M4,11V4H6V6V11C5.1,11 4.3,11 4,11M20,11C19.7,11 18.9,11 18,11V6V4H20V11Z"
          />
        </svg>
      </div>
      <div
        class="button_menu-app button-ranking inline-flex w-1/3 text-center m-auto"
        v-if="!adminRoute"
        :class="{ active: route === 'ranking' }"
        @click="goToDirection('ranking')"
      >
        <svg style="width: 24px; height: 24px" viewBox="0 0 24 24">
          <path
            fill="#ffffff"
            d="M12,7.09L14.45,8.58L13.8,5.77L16,3.89L13.11,3.64L12,1L10.87,3.64L8,3.89L10.18,5.77L9.5,8.58L12,7.09M4,13.09L6.45,14.58L5.8,11.77L8,9.89L5.11,9.64L4,7L2.87,9.64L0,9.89L2.18,11.77L1.5,14.58L4,13.09M20,10.09L22.45,11.58L21.8,8.77L24,6.89L21.11,6.64L20,4L18.87,6.64L16,6.89L18.18,8.77L17.5,11.58L20,10.09M15,23H9V10H15V23M7,23H1V17H7V23M23,23H17V13H23V23Z"
          />
        </svg>
      </div>
      <div
        class="button_menu-app button-ranking inline-flex w-1/3 text-center m-auto"
        v-if="adminRoute"
        :class="{ active: route === 'classrooms' }"
        @click="goToDirection('classrooms')"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          version="1.1"
          width="24"
          height="24"
          viewBox="0 0 24 24"
        >
          <path
            d="M23,2H1C0.45,2 0,2.45 0,3V21C0,21.55 0.45,22 1,22H23C23.55,22 24,21.55 24,21V3C24,2.45 23.55,2 23,2M22,20H20V19H15V20H2V4H22V20M10.29,9.71C10.29,8.77 11.06,8 12,8C12.95,8 13.71,8.77 13.71,9.71C13.71,10.66 12.95,11.43 12,11.43C11.05,11.43 10.29,10.66 10.29,9.71M5.71,11.29C5.71,10.58 6.29,10 7,10C7.71,10 8.29,10.58 8.29,11.29C8.29,12 7.71,12.57 7,12.57C6.29,12.57 5.71,12 5.71,11.29M15.71,11.29C15.71,10.58 16.29,10 17,10C17.71,10 18.29,10.58 18.29,11.29C18.29,12 17.71,12.57 17,12.57C16.29,12.57 15.71,12 15.71,11.29M20,15.14V16H16L14,16H10L8,16H4V15.14C4,14.2 5.55,13.43 7,13.43C7.55,13.43 8.11,13.54 8.6,13.73C9.35,13.04 10.7,12.57 12,12.57C13.3,12.57 14.65,13.04 15.4,13.73C15.89,13.54 16.45,13.43 17,13.43C18.45,13.43 20,14.2 20,15.14Z"
          />
        </svg>
      </div>
      <div
        class="button_menu-app button-profile inline-flex w-1/3 text-center m-auto"
        v-if="adminRoute"
        :class="{ active: route === 'profile' }"
        @click="goToDirection('profile')"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          version="1.1"
          width="24"
          height="24"
          viewBox="0 0 24 24"
        >
          <path
            d="M12,19.2C9.5,19.2 7.29,17.92 6,16C6.03,14 10,12.9 12,12.9C14,12.9 17.97,14 18,16C16.71,17.92 14.5,19.2 12,19.2M12,5A3,3 0 0,1 15,8A3,3 0 0,1 12,11A3,3 0 0,1 9,8A3,3 0 0,1 12,5M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12C22,6.47 17.5,2 12,2Z"
          />
        </svg>
      </div>
      <div
        class="button_menu-app button-return inline-flex w-1/3 text-center m-auto"
        @click="goBack"
      >
        <svg
          v-if="
            route === 'classrooms' || (!user.loggedIn && route === 'ranking')
          "
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          version="1.1"
          width="24"
          height="24"
          viewBox="0 0 24 24"
        >
          <path
            fill="#ffffff"
            d="M13.34,8.17C12.41,8.17 11.65,7.4 11.65,6.47C11.65,5.54 12.41,4.78 13.34,4.78C14.28,4.78 15.04,5.54 15.04,6.47C15.04,7.4 14.28,8.17 13.34,8.17M10.3,19.93L4.37,18.75L4.71,17.05L8.86,17.9L10.21,11.04L8.69,11.64V14.5H7V10.54L11.4,8.67L12.07,8.59C12.67,8.59 13.17,8.93 13.5,9.44L14.36,10.79C15.04,12 16.39,12.82 18,12.82V14.5C16.14,14.5 14.44,13.67 13.34,12.4L12.84,14.94L14.61,16.63V23H12.92V17.9L11.14,16.21L10.3,19.93M21,23H19V3H6V16.11L4,15.69V1H21V23M6,23H4V19.78L6,20.2V23Z"
          />
        </svg>
        <svg v-else style="width: 24px; height: 24px" viewBox="0 0 24 24">
          <path
            fill="#ffffff"
            d="M20,10V14H11L14.5,17.5L12.08,19.92L4.16,12L12.08,4.08L14.5,6.5L11,10H20Z"
          />
        </svg>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "menu-app",
  methods: {
    goToDirection(router) {
      if (this.route !== router) this.$router.push({ name: router });
    },
    goBack() {
      let backRoute = "";

      switch (this.route) {
        case "ranking":
          backRoute = "access";
          break;

        case "student":
        case "markers":
        case "marker":
          backRoute = "back";
          break;
      }

      if (this.user.loggedIn) {
        switch (this.route) {
          case "ranking":
            backRoute = "classrooms";
            break;

          case "originmarkers":
            backRoute = "classrooms";
            break;

          case "originmarker":
            backRoute = "originmarkers";
            break;

          case "marker":
            backRoute = "markers";
            break;

          case "profile":
            backRoute = "classrooms";
            break;

          case "classrooms":
            backRoute = "access";
            break;
        }
      }

      if (backRoute === "back") this.$router.go(-1);
      else if (backRoute === "access") this.goToLogin();
      else this.goToDirection(backRoute);
    },
    goToLogin() {
      var el = this;
      var message =
        "¿Estas seguro que quieres salir de la clase? Para volver a acceder necesitaras el código.";
      if (this.user.loggedIn) message = "¿Estas seguro que quieres salir?";
      this.$dialog
        .confirm(message)
        .then(function () {
          el.$store.commit("setClassroomCode", null);
          el.$store.commit("setLoggedIn", false);
          el.$router.push({ name: "access" });
        })
        .catch(function () {});
    },
  },
  computed: {
    ...mapState(["user", "classroom"]),
    route: function () {
      return this.$route.name;
    },
    adminRoute: function () {
      let adminRoutes = [
        "classrooms",
        "originmarkers",
        "profile",
        "originmarker",
      ];
      if (adminRoutes.indexOf(this.$route.name) !== -1) return true;
      return false;
    },
  },
};
</script>

<style lang="scss">
.menu-app.main-menu {
  display: flex;
  width: 100%;
  max-width: 660px;
  max-height: 60px;
  background: #ed6362;
  position: fixed;
  bottom: 0;

  &.admin {
    background: #fedf5d;

    .button_menu-app {
      &.active {
        background-color: #dac052;
      }

      & + .button_menu-app:not(.active):after {
        background: #dac052;
      }
    }

    path {
      fill: #d35655;
    }
  }

  .button_menu-app {
    position: relative;
    padding: 15px 0;
    transition: 0.4s ease all;

    &.active {
      background-color: #d35655;

      svg {
        transform: scale(1.2);
      }
    }

    svg {
      transition: 0.4s ease all;
      margin: 0 auto;
    }

    & + .button_menu-app:not(.active):after {
      content: " ";
      position: absolute;
      top: calc(50% - 15px);
      right: -1px;
      width: 2px;
      height: 30px;
      background: #d35655;
    }

    &:nth-child(3) {
      &:after {
        content: none;
      }
    }
  }
}

.admin-bar {
  max-width: 660px;
  background: rgba(0, 0, 0, 0.9);
  color: #ffffff;
  bottom: 54px;
  border-top: 3px solid #ed6362;
  padding: 10px 30px;

  h3.nameclass_admin-bar {
    span {
      color: #c1c1c1;
      display: inline-block;
      margin-right: 5px;
      font-size: 13px;
      font-weight: 500;
    }
  }

  .idclass_admin-bar {
    color: #ffe36b;
    span {
      color: #c1c1c1;
      display: inline-block;
      margin-right: 5px;
      font-size: 13px;
      font-weight: 500;
    }
  }

  &.slideTop {
    animation-duration: 1s;
    animation-name: slideTop;
  }
}

@keyframes slideTop {
  from {
    transform: translateY(45px);
  }

  to {
    transform: translateY(0px);
  }
}

.italic-icon {
  transform: skew(-10deg);
}
</style>
