<template>
  <div
    class="student-card flex bg-white"
    @click="student ? goToStudent() : null"
    :class="{ new_student_card: !student, locked }"
  >
    <!-- POSITION STUDENT -->
    <div class="position_student-card inline-flex" v-if="student">
      <img
        class="relative w-10px -top-20"
        v-if="position < 4"
        :src="getTropyImg(colorPosition)"
      />
      <span class="number-ranking" v-else>{{ position }}º</span>
    </div>

    <!-- INFO STUDENT -->
    <div class="data_student-card flex flex-col p-2" v-if="student">
      <h3 class="name-data_student-card w-2/3">{{ student.name }}</h3>
      <div class="markers-wrapper">
        <vuescroll :ops="scrollOptions">
          <div class="markers markers-data_student-card flex" v-if="markers">
            <div
              class="marker item-marker"
              v-for="marker in markersActive"
              :key="marker.id"
            >
              <div v-if="markers[marker.id]">
                <img
                  :src="
                    'https://firebasestorage.googleapis.com/v0/b/' +
                    fb_storage_bucket +
                    '/o/' +
                    markers[marker.id].image +
                    '?alt=media'
                  "
                  :alt="markers[marker.id].title"
                />
              </div>
            </div>
          </div>
        </vuescroll>
      </div>

      <!--progress-bar
        :points="points"
        :maxpoints="maxpoints"
        :minpoints="minpoints"
      ></progress-bar-->
    </div>

    <!-- POINTS STUDENT -->
    <div
      class="
        points-markers_student-card
        markers_student-card
        inline-flex
        justify-center
        items-center
      "
      v-if="student"
    >
      <h4
        class="text-2xl text-purple-900 font-bold text-center"
        style="line-height: 18px"
      >
        {{ points }}
        <span class="text text-xs block">puntos</span>
      </h4>
    </div>

    <div class="content-new_student-card w-full flex" v-if="!student">
      <h3 class="name-data_student-card w-3/4 inline-flex">Añadir Alumno/a</h3>
      <div
        class="
          add-data_student-card
          w-1/4
          font-bold
          text-right
          inline-flex
          justify-end
          items-center
        "
        v-if="!student"
      >
        <svg
          viewBox="0 0 24 24"
          height="32"
          width="32"
          role="img"
          aria-hidden="true"
        >
          <path
            fill="#ffffff"
            d="M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,16.41 16.41,20 12,20M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M13,7H11V11H7V13H11V17H13V13H17V11H13V7Z"
          />
        </svg>
      </div>
    </div>
  </div>
</template>

<script>
//import progressBar from "./progressBar";
import vuescroll from "vuescroll";
import { mapGetters } from "vuex";

export default {
  name: "studentCard",
  props: ["student", "position", "markers", "points", "maxpoints", "minpoints"],
  components: {
    //progressBar
    vuescroll,
  },
  data() {
    return {
      fb_storage_bucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
      scrollOptions: {
        scrollPanel: {
          scrollyY: false,
        },
        bar: {
          size: "3px",
          opacity: 0.5,
          background: "#c1c1c1",
        },
        vuescroll: {
          //sizeStrategy: "number",
        },
      },
    };
  },
  computed: {
    colorPosition: function () {
      switch (this.position) {
        case 1:
          return "gold";
        case 2:
          return "silver";
        case 3:
          return "bronze";
        default:
          return "";
      }
    },
    markersActive() {
      // return this.student.markers;
      return this.student.markers.filter(
        (marker) =>
          typeof this.markers[marker.id] != "undefined" &&
          (marker.points >= this.markers[marker.id].min_points || marker.earned)
      );
    },
    locked() {
      return this.userCan.maxStudentsByClassroom < this.position;
    },
    ...mapGetters(["userCan"]),
  },
  methods: {
    goToStudent() {
      if (this.locked) this.$modal.show("goPremium");
      else
        this.$router.push({ name: "student", params: { id: this.student.id } });
    },
    getTropyImg(color) {
      return require("../assets/img/" + color + "-trophy.png");
    },
  },
};
</script>

<style lang="scss">
.student-card {
  display: flex;
  width: 90%;
  margin: 0 auto;
  color: #333333;
  transform: skew(-10deg);

  .position_student-card {
    width: 70px;
    transform: skew(10deg);

    img {
      width: 70px;
      height: 70px;
      position: relative;
      top: -20px;
    }

    span.number-ranking {
      font-size: 40px;
      font-weight: 700;
      text-align: center;
      display: block;
      margin: 0 auto;
      color: #e95555;
    }
  }

  .data_student-card {
    width: calc(100% - 140px);
    padding-right: 10px;

    h3.name-data_student-card {
      font-size: 23px;
      color: #009688;
      font-weight: 700;
      transform: skew(10deg);
      width: 100%;
      overflow: hidden;
      white-space: nowrap;
      display: block;
      text-overflow: ellipsis;
    }
    .markers-wrapper {
      position: relative;
      &:after {
        position: absolute;
        content: "";
        width: 30px;
        top: -2px;
        bottom: -5px;
        right: -2px;
        /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#ffffff+1,ffffff+100&0+1,1+50 */
        background: -moz-linear-gradient(
          left,
          rgba(255, 255, 255, 0) 1%,
          rgba(255, 255, 255, 1) 50%,
          rgba(255, 255, 255, 1) 100%
        ); /* FF3.6-15 */
        background: -webkit-linear-gradient(
          left,
          rgba(255, 255, 255, 0) 1%,
          rgba(255, 255, 255, 1) 50%,
          rgba(255, 255, 255, 1) 100%
        ); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(
          to right,
          rgba(255, 255, 255, 0) 1%,
          rgba(255, 255, 255, 1) 50%,
          rgba(255, 255, 255, 1) 100%
        ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#ffffff',GradientType=1 ); /* IE6-9 */
      }
      &:before {
        position: absolute;
        content: "";
        width: 15px;
        top: -2px;
        bottom: -5px;
        left: -2px;
        z-index: 1;
        /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#ffffff+1,ffffff+100&0+1,1+50 */
        background: -moz-linear-gradient(
          right,
          rgba(255, 255, 255, 0) 1%,
          rgba(255, 255, 255, 1) 50%,
          rgba(255, 255, 255, 1) 100%
        ); /* FF3.6-15 */
        background: -webkit-linear-gradient(
          right,
          rgba(255, 255, 255, 0) 1%,
          rgba(255, 255, 255, 1) 50%,
          rgba(255, 255, 255, 1) 100%
        ); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(
          to left,
          rgba(255, 255, 255, 0) 1%,
          rgba(255, 255, 255, 1) 50%,
          rgba(255, 255, 255, 1) 100%
        ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#00ffffff',GradientType=1 ); /* IE6-9 */
      }
    }

    .markers {
      transform: skew(10deg);
      width: 100%;
      .marker {
        &:first-child {
          margin-left: 10px;
        }
        width: 20px;
        min-width: 25px;
        margin-left: 3px;
        img {
          width: 20px;
          height: auto;
          margin-top: 3px;
        }
      }
    }
  }

  .points-markers_student-card {
    width: 70px;
  }

  &.new_student_card {
    background-color: rgba(255, 255, 255, 0.66);

    .content-new_student-card {
      height: 60px;
      padding: 15px 25px;

      h3 {
        font-size: 23px;
        color: #ffffff;
        font-weight: 700;
        transform: skew(10deg);
      }

      .add-data_student-card {
        transform: skew(10deg);
      }
    }
  }
}
</style>
