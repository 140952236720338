<template>
  <div class="loading">
    <svg class="animate-spin h-5 w-5 mr-3 ..." viewBox="0 0 24 24">
      <path
        fill="currentColor"
        d="M12,4V2A10,10 0 0,0 2,12H4A8,8 0 0,1 12,4Z"
      />
    </svg>
    Cargando
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.loading {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 20px;
  align-items: center;
}
</style>