<template>
  <div id="list-markers" class="container mx-auto">
    <header
      id="header-section"
      class="header-ranking inline-block w-full mt-12 mb-1 text-center relative"
    >
      <h1 class="text-white text-center text-3xl font-bold">Insignias</h1>
      <h2
        class="
          text-white text-center text-2xl
          font-thin
          uppercase
          tracking-widest
        "
      >
        Globales
      </h2>
      <div class="crud-settings" v-if="user.loggedIn">
        <div class="item_crud-settings" @click="openOrderOptions">
          <svg
            viewBox="0 0 24 24"
            height="32"
            width="32"
            role="img"
            aria-hidden="true"
          >
            <path
              fill="currentColor"
              d="M19 17H22L18 21L14 17H17V3H19M11 13V15L7.67 19H11V21H5V19L8.33 15H5V13M9 3H7C5.9 3 5 3.9 5 5V11H7V9H9V11H11V5C11 3.9 10.11 3 9 3M9 7H7V5H9Z"
            />
          </svg>
        </div>
        <div
          class="item_crud-settings add"
          @click="openAddMarker()"
          v-if="!loading"
        >
          <svg
            viewBox="0 0 24 24"
            height="32"
            width="32"
            role="img"
            aria-hidden="true"
          >
            <path
              fill="#ffffff"
              d="M17,3A2,2 0 0,1 19,5V21L12,18L5,21V5C5,3.89 5.9,3 7,3H17M11,7V9H9V11H11V13H13V11H15V9H13V7H11Z"
            />
          </svg>
        </div>
      </div>
    </header>
    <loading v-if="loading"></loading>
    <transition-group
      id="markers-profile"
      class="list-markers flex-wrap"
      name="list"
      tag="main"
      v-on:enter="enter"
      v-on:before-enter="beforeEnter"
    >
      <origin-marker-card
        v-for="(marker, index) in orderedMarkers"
        :key="marker.id"
        :marker="marker"
        :markers="markers"
        :position="index + 1"
      ></origin-marker-card>
    </transition-group>

    <order-modal
      :options="orderOptions"
      name="orderModal"
      @changeOrder="changeOrder"
    ></order-modal>
    <edit-modal></edit-modal>
  </div>
</template>

<script>
import originMarkerCard from "../components/originMarkerCard";
import Velocity from "velocity-animate";
import orderModal from "../components/orderModal";
import { mapState, mapGetters } from "vuex";
import data from "../services/data";

import editModal from "../components/originMarkerForm";
import Loading from "../components/Loading.vue";

export default {
  name: "origin-markers",
  components: { originMarkerCard, orderModal, editModal, Loading },

  data() {
    return {
      loading: true,
      orderOptions: [
        { name: "Alfabeticamente", value: "title", order: "asc" },
        { name: "Color", value: "color", order: "asc" },
        { name: "Puntuación Ascendente", value: "max_points", order: "asc" },
        { name: "Puntuación Descendente", value: "max_points", order: "desc" },
      ],
      orderBy: { name: "Alfabeticamente", value: "title", order: "asc" },
    };
  },
  computed: {
    ...mapState(["markers", "user"]),
    ...mapGetters(["userCan"]),
    orderedMarkers() {
      let markers = this.markers;

      let order = this.orderBy.order === "asc" ? [1, -1] : [-1, 1];

      return markers.sort((a, b) =>
        a[this.orderBy.value] > b[this.orderBy.value]
          ? order[0]
          : b[this.orderBy.value] > a[this.orderBy.value]
          ? order[1]
          : 0
      );
    },
  },
  methods: {
    changeOrder(value) {
      this.orderBy = value;
    },
    openOrderOptions() {
      this.$modal.show("orderModal");
    },
    beforeEnter: function (el) {
      el.style.opacity = 0;
      el.style.transform = "translate-y(15px)";
    },
    enter: function (el) {
      var delay = el.dataset.index * 100;
      setTimeout(function () {
        Velocity(
          el,
          {
            opacity: 1,
            translateY: 0,
            skew: "-5deg",
          },
          "easeOut"
        );
      }, delay);
    },
    getMarkers() {
      data.getMarkers(this.user.data.id).then((response) => {
        if (response && response.errorMsg) {
          this.$router.push({ name: "access" });
        } else {
          this.loading = false;
          this.$store.commit("setMarkers", response);
        }
      });
    },

    openAddMarker() {
      if (this.userCan.maxMarkers > this.markers.length)
        this.$modal.show("originMarkerForm");
      else this.$modal.show("goPremium");
    },
  },
  created() {
    this.getMarkers();
  },
};
</script>

<style lang="scss">
#markers-profile {
  #item-marker {
    padding: 0.25rem;
    -webkit-transform: skew(-5deg) translateY(0px) !important;
    transform: skew(-5deg) translateY(0px) !important;

    h3.title_item-marker {
      font-size: 19px;
      margin-bottom: 20px;
    }

    h4.max-points_item-marker {
      font-size: 12px;
      position: absolute;
      bottom: 4px;
      left: 2px;
      transform: skew(0deg);
      width: calc(100% + -4px);
      background: #009688;

      span.number-max-points {
        display: inline-block;
        font-size: 19px;
        margin-left: 6px;
        font-weight: 600;
      }
    }
  }
}

span.points-max-classroom {
  color: #ffdb70;
  font-size: 16px;
  font-weight: 600;
  position: relative;
  padding-top: 10px;
  margin-top: 10px;
  display: block;
  text-transform: capitalize;

  &:before {
    content: " ";
    position: absolute;
    top: 0;
    left: calc(50% - 17px);
    width: 34px;
    height: 1px;
    background: #d3bc5b;
  }
}

.list-enter-active,
.list-leave-active {
  transition: all 1s;
}

.list-enter, .list-leave-to /* .list-leave-active below version 2.1.8 */ {
  opacity: 0;
  transform: translateY(30px);
}

.color-input,
.color-marker {
  &.red {
    background-color: rgb(207, 98, 98);
  }
  &.green {
    background-color: rgb(102, 153, 102);
  }
  &.blue {
    background-color: rgb(158, 213, 226);
  }
  &.yellow {
    background-color: rgb(207, 207, 117);
  }
  &.pink {
    background-color: rgb(214, 169, 177);
  }
  &.brown {
    background-color: rgb(68, 34, 34);
  }
}
</style>
