<template>
  <div
    class="classroom-card flex"
    @click="classroom ? goToClassroom() : null"
    :class="{ add: !classroom, locked }"
  >
    <!-- POSITION STUDENT -->

    <!-- INFO STUDENT -->
    <div
      class="name_classroom-card inline-flex flex-wrap w-8/12 p-1 items-center"
    >
      <h3
        class="name-item-classroom w-full"
        v-text="classroom ? classroom.name : 'Añadir Clase'"
      ></h3>
    </div>
    <div
      class="
        data_classroom-card
        inline-flex
        w-4/12
        font-bold
        text-right
        justify-end
        items-center
      "
      v-if="classroom"
    >
      <span class="users-item-classroom ml-2">{{ classroom.id }}</span>
    </div>
    <div
      class="
        data_classroom-card
        inline-flex
        w-4/12
        font-bold
        text-right
        justify-end
        items-center
      "
      v-if="!classroom"
    >
      <svg
        viewBox="0 0 24 24"
        height="32"
        width="32"
        role="img"
        aria-hidden="true"
      >
        <path
          fill="#ffffff"
          d="M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,16.41 16.41,20 12,20M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M13,7H11V11H7V13H11V17H13V13H17V11H13V7Z"
        />
      </svg>
    </div>
  </div>
</template>

<script>
import data from "../services/data";
import { mapGetters } from "vuex";

export default {
  name: "classroomCard",
  props: ["classroom", "position"],
  components: {},

  computed: {
    ...mapGetters(["userCan"]),
    locked() {
      return this.position > this.userCan.maxClassrooms;
    },
  },
  methods: {
    goToClassroom() {
      if (this.locked) this.$modal.show("goPremium");
      else
        data.getClassRoom(this.classroom.id).then((response) => {
          if (response && response.errorMsg) {
            if (this.$store.state.classroomCode)
              this.$store.commit("setClassroomCode", null);
            else this.error = true;
          } else {
            this.$store.commit("setClassroomCode", this.classroom.id);
            this.$router.push({ name: "ranking" });
          }
        });
    },
  },
};
</script>

<style lang="scss">
.classroom-card {
  display: flex;
  width: 90%;
  margin: 0 auto;
  background: #009688;
  padding: 7.5px 15px;
  transform: skew(-10deg);

  &.add {
    background: rgba(138, 181, 177, 0.55);
  }

  .name_classroom-card {
    padding-right: 10px;

    h3.name-item-classroom {
      font-size: 23px;
      color: #fff;
      font-weight: 700;
      transform: skew(10deg);
    }
  }

  .data_classroom-card {
    position: relative;
    color: #ffe36b;
    font-size: 16px;

    svg {
      transform: skew(10deg);
      position: absolute;
      left: -7.5px;
      width: 20px;
    }
  }
}
</style>
