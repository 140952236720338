<template>
  <div id="principal">
    <h1>My Badges</h1>
    <h2>Mis insignias</h2>
    <h2>Les meues insígnies</h2>
    <img
      src="../assets/img/logo-MBM.png"
      class="logo-app relative w-10px -top-20"
    />
    <div class="form-access">
      <input
        type="text"
        v-model="classroomCodeInput"
        placeholder="Introduce ID del curso"
      />
      <button @click="goToClassroom">
        <span class="text-button">Acceder</span>
      </button>
      <div class="access-error" v-if="error">
        Error de Acceso: No existe ninguna clase con este ID.
      </div>
    </div>

    <div @click="openLogin" class="btn-access-prof mb-3">
      Acceso Profesorado
    </div>

    <!--a
      href="https://advservices-my.sharepoint.com/:w:/g/personal/formacion_advservices_es/Eb8oEoNYLkxCtqkE-TlAIlgBwZmnqBqKoMRJvRQLFdWMbQ"
      class="btn-lopd mt-5 text-sm"
      target="_blank"
    >
      Política de Privacidad
    </a-->

    <!--a
      href="https://advservices-my.sharepoint.com/:w:/g/personal/formacion_advservices_es/EavFu3n4Ah9EhiW8I57m2VQBgZez6f8yymvTKOkfQX8iCA"
      class="btn-lopd mt-5 text-sm"
      target="_blank"
      >Términos y condiciones</a
    -->
    <div class="rrss flex mt-5">
      <a
        href="https://instagram.com/mybadges.app?igshid=YmMyMTA2M2Y="
        target="_blank"
        class="mx-1"
      >
        <svg style="width: 24px; height: 24px" viewBox="0 0 24 24">
          <path
            fill="currentColor"
            d="M7.8,2H16.2C19.4,2 22,4.6 22,7.8V16.2A5.8,5.8 0 0,1 16.2,22H7.8C4.6,22 2,19.4 2,16.2V7.8A5.8,5.8 0 0,1 7.8,2M7.6,4A3.6,3.6 0 0,0 4,7.6V16.4C4,18.39 5.61,20 7.6,20H16.4A3.6,3.6 0 0,0 20,16.4V7.6C20,5.61 18.39,4 16.4,4H7.6M17.25,5.5A1.25,1.25 0 0,1 18.5,6.75A1.25,1.25 0 0,1 17.25,8A1.25,1.25 0 0,1 16,6.75A1.25,1.25 0 0,1 17.25,5.5M12,7A5,5 0 0,1 17,12A5,5 0 0,1 12,17A5,5 0 0,1 7,12A5,5 0 0,1 12,7M12,9A3,3 0 0,0 9,12A3,3 0 0,0 12,15A3,3 0 0,0 15,12A3,3 0 0,0 12,9Z"
          />
        </svg>
      </a>
      <!-- facebook a href="#" target="_blank" class="mx-1">
        <svg style="width: 24px; height: 24px" viewBox="0 0 24 24">
          <path
            fill="currentColor"
            d="M12 2.04C6.5 2.04 2 6.53 2 12.06C2 17.06 5.66 21.21 10.44 21.96V14.96H7.9V12.06H10.44V9.85C10.44 7.34 11.93 5.96 14.22 5.96C15.31 5.96 16.45 6.15 16.45 6.15V8.62H15.19C13.95 8.62 13.56 9.39 13.56 10.18V12.06H16.34L15.89 14.96H13.56V21.96A10 10 0 0 0 22 12.06C22 6.53 17.5 2.04 12 2.04Z"
          />
        </svg>
      </a-->
      <a
        href="https://twitter.com/mybadges_app?s=21&t=EpHEgT08LdbME0nNsJi92A"
        target="_blank"
        class="mx-1"
      >
        <svg style="width: 24px; height: 24px" viewBox="0 0 24 24">
          <path
            fill="currentColor"
            d="M22.46,6C21.69,6.35 20.86,6.58 20,6.69C20.88,6.16 21.56,5.32 21.88,4.31C21.05,4.81 20.13,5.16 19.16,5.36C18.37,4.5 17.26,4 16,4C13.65,4 11.73,5.92 11.73,8.29C11.73,8.63 11.77,8.96 11.84,9.27C8.28,9.09 5.11,7.38 3,4.79C2.63,5.42 2.42,6.16 2.42,6.94C2.42,8.43 3.17,9.75 4.33,10.5C3.62,10.5 2.96,10.3 2.38,10C2.38,10 2.38,10 2.38,10.03C2.38,12.11 3.86,13.85 5.82,14.24C5.46,14.34 5.08,14.39 4.69,14.39C4.42,14.39 4.15,14.36 3.89,14.31C4.43,16 6,17.26 7.89,17.29C6.43,18.45 4.58,19.13 2.56,19.13C2.22,19.13 1.88,19.11 1.54,19.07C3.44,20.29 5.7,21 8.12,21C16,21 20.33,14.46 20.33,8.79C20.33,8.6 20.33,8.42 20.32,8.23C21.16,7.63 21.88,6.87 22.46,6Z"
          />
        </svg>
      </a>
      <a
        href="https://youtube.com/playlist?list=PLrSUt28XoLz8CY9YqX049cLc-LVb5oj9p"
        target="_blank"
        class="mx-1"
      >
        <svg style="width: 24px; height: 24px" viewBox="0 0 24 24">
          <path
            fill="currentColor"
            d="M10,15L15.19,12L10,9V15M21.56,7.17C21.69,7.64 21.78,8.27 21.84,9.07C21.91,9.87 21.94,10.56 21.94,11.16L22,12C22,14.19 21.84,15.8 21.56,16.83C21.31,17.73 20.73,18.31 19.83,18.56C19.36,18.69 18.5,18.78 17.18,18.84C15.88,18.91 14.69,18.94 13.59,18.94L12,19C7.81,19 5.2,18.84 4.17,18.56C3.27,18.31 2.69,17.73 2.44,16.83C2.31,16.36 2.22,15.73 2.16,14.93C2.09,14.13 2.06,13.44 2.06,12.84L2,12C2,9.81 2.16,8.2 2.44,7.17C2.69,6.27 3.27,5.69 4.17,5.44C4.64,5.31 5.5,5.22 6.82,5.16C8.12,5.09 9.31,5.06 10.41,5.06L12,5C16.19,5 18.8,5.16 19.83,5.44C20.73,5.69 21.31,6.27 21.56,7.17Z"
          />
        </svg>
      </a>
    </div>
    <div class="flex flex-col text-center items-center mt-5">
      <p class="text-white text-xs">Una creación de Vicent González Álvarez</p>

      <a rel="license" href="http://creativecommons.org/licenses/by-nc-sa/4.0/"
        ><img
          alt="Licencia de Creative Commons"
          style="border-width: 0; width: 50px; margin-top: 10px"
          src="https://i.creativecommons.org/l/by-nc-sa/4.0/88x31.png"
      /></a>
    </div>
    <lopd></lopd>
    <modal
      name="login"
      :clickToClose="false"
      height="auto"
      class="form-modal"
      :scrollable="true"
    >
      <h1 class="title-modal">Acceso Profesorado</h1>
      <div class="reset-ok" v-if="resetOk">
        Recibirás un email con un link para resetar tu password
      </div>
      <p
        class="text-xs text-center mt-1 mb-1 text-green-500"
        v-if="passwordUpdated"
      >
        Contraseña actualizada
      </p>
      <input
        type="email"
        v-model="loginEmail"
        placeholder="Introduce tu Email"
        class="mb-2"
      />
      <input
        type="password"
        v-model="loginPassword"
        placeholder="Introduce tu Password"
      />
      <div class="login-error text-center" v-if="loginError">
        {{ loginError }}
      </div>
      <button @click="login" class="w-1-2">
        <span class="text-button">Acceder</span>
      </button>
      <button @click="closeLogin" class="w-1-2">
        <span class="text-button">Cancelar</span>
      </button>
      <div class="flex justify-around">
        <div @click="openReset" class="btn-open-reset text-sm">
          Recuperar contraseña
        </div>
      </div>
      <div class="flex justify-center" v-if="enableRegister">
        <div @click="openRegister" class="btn-open-register">Registrarse</div>
      </div>
    </modal>

    <modal
      name="register"
      :clickToClose="false"
      height="auto"
      class="form-modal"
      :scrollable="true"
      v-if="enableRegister"
    >
      <h1 class="title-modal titleNewAccount">Nueva cuenta Profesor/a</h1>
      <input
        type="text"
        v-model="registerName"
        placeholder="Introduce tu Nombre"
        class="mb-2"
      />
      <input
        type="email"
        v-model="registerEmail"
        placeholder="Introduce tu Email"
        class="mb-2"
      />
      <input
        type="password"
        v-model="registerPassword"
        placeholder="Introduce tu Password"
      />
      <div class="flex justify-center items-center py-3">
        <input
          type="checkbox"
          v-model="registerTerms"
          :value="true"
          style="width: 20px"
        />
        <div>
          Acepto los
          <a
            href="https://drive.google.com/file/d/1OqAklozrkGU0cESlcxYcm6FQzQO6IKPp/view?usp=share_link"
            target="_blank"
            class="underline"
            >términos y condiciones</a
          >
          y la
          <a
            href="https://drive.google.com/file/d/14jEZG19ymkMA1IetHTlJc9QMfMGQodGb/view?usp=share_link"
            target="_blank"
            class="underline"
            >política de privacidad.</a
          >
        </div>
      </div>
      <div class="register-error" v-if="registerError">
        {{ registerError }}
      </div>
      <button @click="closeRegister" class="w-1-2 close-modal">
        <span class="text-button">Cancelar</span>
      </button>
      <button
        @click="register"
        class="w-1-2"
        :class="{ 'btn-disabled': !registerTerms }"
      >
        <span class="text-button">Registrarse</span>
      </button>
    </modal>

    <modal
      name="reset"
      :clickToClose="false"
      height="auto"
      class="form-modal"
      :scrollable="true"
    >
      <h1 class="title-modal">Recuperar Password</h1>
      <input
        type="email"
        v-model="resetEmail"
        placeholder="Introduce tu Email"
      />
      <div class="reset-error" v-if="resetError">
        {{ resetError }}
      </div>

      <button @click="reset" class="w-1-2">
        <span class="text-button">Resetear Password</span>
      </button>
      <button @click="closeReset" class="w-1-2">
        <span class="text-button">Cancelar</span>
      </button>
    </modal>
    <modal
      name="changePassword"
      :clickToClose="false"
      height="auto"
      class="form-modal"
      :scrollable="true"
    >
      <h1 class="title-modal">Cambiar contraseña</h1>
      <p class="text-center mb-4">{{ resetEmail }}</p>

      <input
        type="password"
        v-model="resetNewPassword"
        placeholder="Nuevo Password"
        class="mb-2"
      />
      <input
        type="password"
        v-model="resetNewnewPassword"
        placeholder="Repite Nuevo Password"
        class="mb-2"
      />
      <div
        class="changePassword-error text-center bg-red-600 p-1"
        v-if="changePasswordError"
      >
        {{ changePasswordError }}
      </div>
      <button @click="changePassword" class="w-1-2">
        <span class="text-button">Guardar</span>
      </button>
      <button @click="closeChangePassword" class="w-1-2">
        <span class="text-button">Cancelar</span>
      </button>
    </modal>
  </div>
</template>

<script>
import data from "../services/data";
import { mapState } from "vuex";
import Lopd from "../components/lopd.vue";

export default {
  name: "Access",
  components: { Lopd },
  data() {
    return {
      classroomCodeInput: "",
      error: false,
      loginEmail: "",
      loginPassword: "",
      registerName: "",
      registerEmail: "",
      registerPassword: "",
      loginError: false,
      registerError: false,
      resetError: false,
      resetEmail: "",
      resetOk: false,
      resetCode: "",
      resetNewPassword: "",
      resetNewnewPassword: "",
      changePasswordError: false,
      passwordUpdated: false,
      enableRegister: true,
      registerTerms: false,
    };
  },
  computed: {
    ...mapState(["classroomCode", "user"]),
  },

  methods: {
    goToClassroom() {
      // if (this.classroomCodeInput == "CODIGO") {
      //     this.classroomCodeInput = "bQYTm6v09MPSlnO9Nsi5";
      // }

      data.getClassRoom(this.classroomCodeInput).then((response) => {
        if (response && response.errorMsg) {
          if (this.$store.state.classroomCode)
            this.$store.commit("setClassroomCode", null);
          else this.error = true;
        } else {
          this.$store.commit("setClassroomCode", this.classroomCodeInput);
          this.$router.push({ name: "ranking" });
        }
      });
    },

    openLogin() {
      this.$modal.show("login");
    },

    openRegister() {
      this.closeLogin();
      this.$modal.show("register");
    },

    openReset() {
      this.$modal.show("reset");
    },

    closeLogin() {
      this.$modal.hide("login");
    },

    closeRegister() {
      this.$modal.hide("register");
    },

    closeReset() {
      this.$modal.hide("reset");
    },
    closeChangePassword() {
      this.$modal.hide("changePassword");
    },

    login() {
      data
        .login(this.loginEmail, this.loginPassword)
        .then(() => {
          this.$router.push({ name: "classrooms" }).catch(() => {});
          this.loginError = false;
        })
        .catch(() => {
          // console.error(message);
          this.loginError = "Email y/o contraseña incorrectos.";
        });
    },
    register() {
      data
        .register(this.registerEmail, this.registerPassword)
        .then(() => {
          data
            .login(this.registerEmail, this.registerPassword, { role: "free" })
            .then(() => {
              data.updateProfile({
                displayName: this.registerName,
              });
              this.$router.push({ name: "classrooms" }).catch(() => {});
              this.loginError = false;
            });
        })
        .catch((error) => {
          this.registerError = error;
        });
    },
    reset() {
      data
        .sendPasswordResetEmail(this.resetEmail)
        .then(() => {
          this.closeReset();
          this.resetOk = true;
          this.loginError = false;
        })
        .catch((error) => {
          this.resetError = error;
        });
    },

    checkIfResetUrlActive() {
      if (this.$route.query.mode == "resetPassword") {
        this.openLogin();
        data
          .verifyPasswordResetCode(this.$route.query.oobCode)
          .then((email) => {
            this.closeLogin();
            this.resetEmail = email;
            this.resetCode = this.$route.query.oobCode;
            this.$modal.show("changePassword");
          })
          .catch((error) => {
            if (error) this.loginError = error;
          });
      }
    },
    changePassword() {
      if (
        this.resetNewPassword.length < 6 ||
        this.resetNewPassword != this.resetNewnewPassword
      ) {
        this.changePasswordError =
          "Las contraseñas no coinciden o tienen menos de 6 carácteres.";
      } else
        data
          .confirmPasswordReset(this.resetCode, this.resetNewPassword)
          .then(() => {
            this.closeChangePassword();
            this.openLogin();
            this.passwordUpdated = true;
          })
          .catch((error) => {
            this.changePasswordError = error;
          });
    },
  },

  created() {
    this.error = false;
  },
  mounted() {
    if (this.$store.state.classroomCode) {
      this.classroomCodeInput = this.$store.state.classroomCode;
      this.goToClassroom();
    }

    if (this.user.loggedIn) this.$router.push({ name: "classrooms" });
    else this.checkIfResetUrlActive();
  },
  watch: {
    "user.loggedIn": function (value) {
      if (value) this.$router.push({ name: "classrooms" });
    },
  },
};
</script>

<style lang="scss">
#principal {
  width: 100%;
  min-height: 100vh;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  flex-wrap: wrap;

  h1 {
    font-size: 48px;
    font-weight: 700;
    text-align: center;
    margin-bottom: 0px;
    &.titleNewAccount {
      font-size: 32px;
      line-height: 34px;
    }
  }

  img.logo-app {
    width: 240px;
    margin-bottom: 30px;
  }

  .form-access {
    input,
    input[type="text"] {
      background: rgba(38, 38, 38, 0.33);
      border: 2px solid #009385;
      color: #fff;
      padding: 10px;
      width: 100%;
      text-align: center;
    }

    button {
      background: #009688;
      width: 100%;
      padding: 15px;
      margin-top: 10px;
      background-image: linear-gradient(to right, #173e3b, #009688);
      transform: skew(-5deg);
      text-transform: uppercase;

      span {
        transform: skew(5deg);
      }
    }
  }

  .btn-access-prof {
    margin-top: 30px;
    border-bottom: 1px solid #fff;
    padding: 7.5px;
  }

  .btn-open-register {
    margin-top: 30px;
    border-bottom: 1px solid #fff;
    padding: 7.5px;
  }

  .btn-open-reset {
    margin-top: 15px;
    padding: 0.5rem;
  }

  .access-error,
  .login-error,
  .register-error,
  .reset-ok {
    margin-top: 10px;
    background: #be4242;
    color: white;
    padding: 10px;
  }

  .reset-ok {
    margin-bottom: 10px;
  }

  .lopd {
    font-size: 10px;
    padding: 10px;
    text-align: center;
    margin-top: 60px;
  }

  .btn-lopd {
    margin-top: 30px;
    border-bottom: 1px solid #fff;
    padding: 4px;
  }
}
</style>
