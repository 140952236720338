<template>
  <vue-pull-refresh :on-refresh="refresh" :config="refreshConfig">
    <div class="container mx-auto marker-view" v-if="marker">
      <header
        id="header-marker"
        class="
          header-markertext-center
          flex flex-col
          items-center
          pt-10
          pb-5
          relative
        "
      >
        <div class="icon_marker">
          <img
            :src="
              'https://firebasestorage.googleapis.com/v0/b/' +
              fb_storage_bucket +
              '/o/' +
              marker.image +
              '?alt=media'
            "
            class="relative w-10px -top-20"
            :alt="marker.title"
          />
        </div>
        <div class="flex items-center justify-center mb-3">
          <div
            class="color-marker w-5 h-5 rounded-full mx-2"
            :class="marker.color"
          ></div>
          <svg
            style="width: 34px; height: 34px; color: yellow"
            viewBox="0 0 24 24"
            class="mx-2"
            v-if="marker.new"
          >
            <path
              fill="currentColor"
              d="M20,4C21.11,4 22,4.89 22,6V18C22,19.11 21.11,20 20,20H4C2.89,20 2,19.11 2,18V6C2,4.89 2.89,4 4,4H20M8.5,15V9H7.25V12.5L4.75,9H3.5V15H4.75V11.5L7.3,15H8.5M13.5,10.26V9H9.5V15H13.5V13.75H11V12.64H13.5V11.38H11V10.26H13.5M20.5,14V9H19.25V13.5H18.13V10H16.88V13.5H15.75V9H14.5V14A1,1 0 0,0 15.5,15H19.5A1,1 0 0,0 20.5,14Z"
            />
          </svg>

          <svg
            style="width: 34px; height: 34px; color: white"
            viewBox="0 0 24 24"
            class="mx-2"
            v-if="marker.locked"
          >
            <path
              fill="currentColor"
              d="M10 17C11.1 17 12 16.1 12 15C12 13.9 11.1 13 10 13C8.9 13 8 13.9 8 15S8.9 17 10 17M16 8C17.1 8 18 8.9 18 10V20C18 21.1 17.1 22 16 22H4C2.9 22 2 21.1 2 20V10C2 8.9 2.9 8 4 8H5V6C5 3.2 7.2 1 10 1S15 3.2 15 6V8H16M10 3C8.3 3 7 4.3 7 6V8H13V6C13 4.3 11.7 3 10 3M22 13H20V7H22V13M22 17H20V15H22V17Z"
            />
          </svg>
        </div>
        <div class="data_marker">
          <h1 class="title-data_marker">
            {{ marker.title }}
          </h1>

          <p class="description-data_marker whitespace-pre-wrap">
            {{ marker.description }}
          </p>
          <h2 class="mt-5 title-evaluation" v-if="marker.evaluation.length">
            Evaluación
          </h2>
          <p
            class="description-data_marker whitespace-pre-wrap"
            v-if="marker.evaluation.length"
          >
            {{ marker.evaluation }}
          </p>
          <div class="flex justify-center">
            <div class="mx-4">
              <h2 class="mt-5 title-evaluation">Mínimo</h2>
              <p class="description-data_marker whitespace-pre-wrap">
                {{ marker.min_points }} puntos
              </p>
            </div>
            <div class="mx-4">
              <h2 class="mt-5 title-evaluation">Máximo</h2>
              <p class="description-data_marker whitespace-pre-wrap">
                {{ marker.max_points }} puntos
              </p>
            </div>
          </div>
        </div>
      </header>
      <div class="crud-settings" style="top: 35px" v-if="user.loggedIn">
        <div class="item_crud-settings edit" @click="editMarker()">
          <svg
            viewBox="0 0 24 24"
            height="32"
            width="32"
            role="img"
            aria-hidden="true"
          >
            <path
              fill="#ffffff"
              d="M20.71,7.04C21.1,6.65 21.1,6 20.71,5.63L18.37,3.29C18,2.9 17.35,2.9 16.96,3.29L15.12,5.12L18.87,8.87M3,17.25V21H6.75L17.81,9.93L14.06,6.18L3,17.25Z"
            />
          </svg>
        </div>
        <div class="item_crud-settings edit" @click="qdelete()">
          <svg
            viewBox="0 0 24 24"
            height="32"
            width="32"
            role="img"
            aria-hidden="true"
          >
            <path
              fill="currentColor"
              d="M19,4H15.5L14.5,3H9.5L8.5,4H5V6H19M6,19A2,2 0 0,0 8,21H16A2,2 0 0,0 18,19V7H6V19Z"
            />
          </svg>
        </div>
      </div>
      <main class="list-ranking pt-10 pb-6" v-if="rankedStudentsByMarker">
        <div
          id="item-student"
          class="item-ranking mb-10"
          v-for="(student, position) in rankedStudentsByMarker"
          :key="student.id"
        >
          <student-card
            v-if="position < 5 && student.marker_points"
            :student="student"
            :position="position + 1"
            :points="student.marker_points"
            :maxpoints="marker.max_points"
            :minpoints="marker.min_points"
          ></student-card>
        </div>
      </main>
      <edit-modal :marker="this.marker"></edit-modal>
    </div>
  </vue-pull-refresh>
</template>

<script>
import studentCard from "../components/studentCard";
import { mapState } from "vuex";
import data from "../services/data";
import helpers from "../services/helpers";
import VuePullRefresh from "vue-awesome-pull-refresh";
import editModal from "../components/markerForm";

export default {
  name: "localMarker",
  props: [],
  components: { editModal, studentCard, VuePullRefresh },
  data() {
    return {
      refreshConfig: {
        errorLabel: "Ha habido un error",
        startLabel: "Actualizar datos",
        readyLabel: "Suelta para actualizar",
        loadingLabel: "Actualizando datos",
        pullDownHeight: 40,
      },
      fb_storage_bucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
    };
  },
  computed: {
    marker() {
      return this.$store.getters.classroomMarker(this.$route.params.id);
    },
    rankedStudentsByMarker() {
      return this.$store.getters.rankedClassroomStudentsByMarker(
        this.$route.params.id
      );
    },
    ...mapState(["user", "classroomCode"]),
  },
  methods: {
    editMarker() {
      this.$modal.show("markerForm");
    },
    qdelete() {
      this.$dialog
        .confirm("¿Estas seguro que quieres eliminar la insignia?")
        .then(() => {
          this.deleteMarker();
        })
        .catch(function () {});
    },
    deleteMarker() {
      data
        .deleteClassroomMarker(this.classroomCode, this.marker.id)
        .then(() => {
          this.refresh().then(() => {
            this.$router.push({
              name: "markers",
            });
          });
        })
        .catch(() => {});
    },
    refresh() {
      return new Promise((resolve, reject) => {
        data.getClassroomStudents(this.classroomCode).then((response) => {
          if (response && response.errorMsg) {
            reject();
          } else {
            this.$store.commit(
              "setClassroomStudents",
              helpers.addTotalPointsToStudents(response)
            );
            data.getClassroomMarkers(this.classroomCode).then((response) => {
              if (response && response.errorMsg) {
                reject();
              } else {
                this.$store.commit("setClassroomMarkers", response);
                resolve();
              }
            });
          }
        });
      });
    },
  },
};
</script>

<style lang="scss">
.marker-view {
  header#header-marker {
    width: 100%;
    height: auto;
    background-image: linear-gradient(to bottom, #173e3b, #009688);

    .icon_marker {
      img {
        width: 120px;
        display: block;
        margin: 0 auto 10px auto;
      }
    }

    .data_marker {
      width: 100%;
      text-align: center;

      h1,
      .title-data_marker {
        font-size: 30px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: normal;
        color: #ffffff;
        padding: 0 25px;
      }

      .description-data_marker {
        font-size: 11px;
        padding: 0 25px;
      }

      h2,
      .title-evaluation {
        font-size: 16px;
        font-weight: 500;
      }
    }
  }
}
</style>