<template>
  <div>
    <div class="modal-wrapper">
      <div class="close" @click="$emit('close')"></div>
      <div class="main-content">
        <img class="app-logo" src="@/assets/img/logo-MBM.png" />
        <h3 class="title">Instala My Badges</h3>
        <p class="description">
          Instale esta aplicación en su pantalla de inicio de forma rápida y
          fácil.
        </p>
      </div>

      <div class="footer">
        Pulsa
        <img
          class="apple-navigation-action-img"
          src="@/assets/img/apple-navigation-action.png"
        />y 'Añadir a pantalla de Inicio'
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.modal-wrapper {
  box-shadow: 0px 0px 20px 0px #656565;
  margin: 30px;
  background: white;
  position: relative;
  color: black;
  .close {
    position: absolute;
    right: 10px;
    top: 10px;
    width: 21px;
    height: 21px;
    opacity: 0.3;
  }
  .close:hover {
    opacity: 1;
  }
  .close:before,
  .close:after {
    position: absolute;
    left: 8px;
    content: " ";
    height: 22px;
    width: 2px;
    background-color: #333;
  }
  .close:before {
    transform: rotate(45deg);
  }
  .close:after {
    transform: rotate(-45deg);
  }
  .main-content {
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .app-logo {
      max-width: 50px;
      margin-bottom: 10px;
    }
    .title {
      margin: 5px 0;
      text-align: center;
    }
    .description {
      margin: 5px 0;
      text-align: center;
    }
  }
  .footer {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    background-color: #f9f9f9;
    font-size: 12px;
    .apple-navigation-action-img {
      max-width: 15px;
      margin: 0 10px;
    }
  }
}
</style>