<template>
  <modal
    :name="name"
    :clickToClose="false"
    height="auto"
    class="form-modal"
    :scrollable="true"
  >
    <h1 class="title-modal">Ordenar por:</h1>

    <button
      v-for="option in options"
      :key="option.name"
      @click="changeOrder(option)"
      class="w-full"
    >
      <span class="text-button">{{ option.name }}</span>
    </button>
  </modal>
</template>

<script>
export default {
  props: ["name", "options"],
  methods: {
    changeOrder(value) {
      this.$emit("changeOrder", value);
      this.$modal.hide(this.name);
    },
  },
};
</script>

<style>
</style>