export default {
  orderStudentsByPoints(students) {
    return students.sort((a, b) => b.points - a.points);
  },
  addTotalPointsToStudents(students) {
    students.forEach(function(student, index) {
      // students[index].points = this.addTotalPointsToStudent(student);
      var total_points = 0;
      student.markers.forEach(function(marker) {
        total_points += marker.points;
      });
      students[index].points = total_points;
    });
    return students;
  },
  addTotalPointsToStudent(student) {
    var total_points = 0;
    student.markers.forEach(function(marker) {
      total_points += marker.points;
    });

    student.points = total_points;

    return student;
  },
  addPositionToStudents(students) {
    if (students.length) {
      students = students.sort((a, b) => b.points - a.points);
      students.forEach(function(student, index) {
        students[index].position = index + 1;
      });
    }
    return students;
  },
  filterAndAddPositionToStudentsByMarker(students, marker_id) {
    students.forEach(function(student, index) {
      var total_points = 0;
      student.markers.forEach(function(marker) {
        if (marker.id == marker_id) total_points += marker.points;
      });
      students[index].marker_points = total_points;
    });

    students = students.sort((a, b) => b.marker_points - a.marker_points);
    students.forEach(function(student, index) {
      students[index].marker_position = index + 1;
    });

    return students;
  },
  generateID() {
    const chars = "ABCDEFGHIJKLMNPQRSTUVWXYZ123456789";
    let autoId = "";
    for (let i = 0; i < 6; i++) {
      autoId += chars.charAt(Math.floor(Math.random() * chars.length));
    }
    return autoId;
  }
};
