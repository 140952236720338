import Vue from "vue";
import VueRouter from "vue-router";
import Ranking from "../views/Ranking.vue";
import Student from "../views/Student.vue";
import Access from "../views/Access.vue";
import Marker from "../views/Marker.vue";
import Profile from "../views/Profile.vue";
import Classrooms from "../views/Classrooms";
// import Test from "../views/Test.vue";
import ListMarkers from "../views/ListMarkers.vue";
import OriginMarkers from "../views/OriginMarkers.vue";
import OriginMarker from "../views/OriginMarker.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "ranking",
    component: Ranking,
  },
  {
    path: "/access",
    name: "access",
    component: Access,
  },
  {
    path: "/profile",
    name: "profile",
    component: Profile,
  },
  {
    path: "/classrooms",
    name: "classrooms",
    component: Classrooms,
  },
  {
    path: "/originmarkers",
    name: "originmarkers",
    component: OriginMarkers,
  },
  {
    path: "/originmarkers/:id",
    name: "originmarker",
    component: OriginMarker,
  },
  {
    path: "/student/:id",
    name: "student",
    component: Student,
  },
  {
    path: "/marker/:id",
    name: "marker",
    component: Marker,
  },
  {
    path: "/markers",
    name: "markers",
    component: ListMarkers,
  },
  // {
  //   path: "/test",
  //   name: "test",
  //   component: Test
  // }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
