<template>
  <modal
    name="originMarkerForm"
    :clickToClose="false"
    height="auto"
    class="form-modal"
    :scrollable="true"
  >
    <h1
      class="title-modal"
      v-text="marker ? 'Editar Insignia' : 'Añadir Insignia'"
    ></h1>

    <div class="input-modal text-center">
      <span class="label">Título:</span>
      <input
        type="text"
        v-model="markerForm.title"
        placeholder="Introduce una descripción"
      />
    </div>

    <div class="input-modal text-center">
      <span class="label">Descripción:</span>
      <textarea-autosize
        :min-height="50"
        v-model="markerForm.description"
        placeholder="Introduce una descripción"
      />
    </div>

    <div class="input-modal text-center">
      <span class="label">Evaluación:</span>
      <textarea-autosize
        :min-height="50"
        v-model="markerForm.evaluation"
        placeholder="Evaluación para conseguir el marker"
      />
    </div>

    <div class="input-modal text-center">
      <span class="label">Puntuación Mínima:</span>
      <number-input v-model="markerForm.min_points" :min="1" inline controls
        ><span class="label">Puntuación Mínima:</span>
      </number-input>
    </div>

    <div class="input-modal text-center">
      <span class="label">Puntuación Máxima:</span>
      <number-input v-model="markerForm.max_points" :min="1" inline controls
        ><span class="label">Puntuación Mínima:</span>
      </number-input>
    </div>

    <div class="input-modal text-center">
      <span class="label">Color:</span>
      <div class="color-inputs flex justify-center">
        <div
          class="color-input w-10 h-10 border-2 mx-2 border-white"
          v-for="color in colorOptions"
          :class="color + (color == markerForm.color ? '' : ' opacity-50')"
          :key="color"
          @click="(markerForm.color = color) && $forceUpdate()"
        ></div>
      </div>
    </div>

    <div class="input-modal text-center">
      <span class="label">Imagen:</span>
      <div class="flex content_input-image">
        <div class="w-1/3 img-content_input-image inline-flex items-center">
          <img
            class="preview"
            style="max-width: 100px; width: 100%; height: auto; margin: 0 auto"
            :src="imageForm.picture"
            v-if="imageForm.picture != null"
          />
          <img
            class="preview"
            style="max-width: 100px; width: 100%; height: auto; margin: 0 auto"
            src="../assets/img/no-image.jpg"
            v-else
          />
        </div>
        <div class="w-2/3 buttons-content_input-image px-6">
          <button @click="chosseImage" class="white">Elegir Imagen</button>
          <input
            type="file"
            id="inputImage"
            @change="preloadImage"
            accept="image/*"
            hidden
          />
          <p v-if="imageForm.uploadValue">
            Cargando: {{ imageForm.uploadValue.toFixed() + "%" }}
            <progress
              id="progress"
              :value="imageForm.uploadValue"
              max="100"
            ></progress>
          </p>
          <div v-if="imageForm.imageData != null && !imageForm.uploadValue">
            <button
              @click="uploadImage"
              class="white"
              style="margin-top: 0 !important"
            >
              Confirmar Imagen
            </button>
            <br />
          </div>
        </div>
      </div>
    </div>
    <button
      @click="closeModal"
      class="w-1-2 close-modal"
      style="background: rgba(0, 0, 0, 0.75) !important"
    >
      <span class="text-button">Cancelar</span>
    </button>
    <button @click="addMarker" class="w-1-2 save-modal" v-if="!markerForm.id">
      <span class="text-button">Añadir</span>
    </button>
    <button @click="updateMarker" class="w-1-2 save-modal" v-else>
      <span class="text-button">Guardar</span>
    </button>
  </modal>
</template>

<script>
import NumberInput from "@chenfengyuan/vue-number-input";
import data from "../services/data";
import { storage } from "../services/firebase";
import { mapState } from "vuex";

export default {
  name: "originMarkerForm",
  components: { NumberInput },
  props: ["marker"],
  data() {
    return {
      markerForm: {
        description: "",
        evaluation: "",
        max_points: 50,
        min_points: 10,
        image: "",
        title: "",
        color: "",
        user: null,
      },
      imageForm: {
        imageData: null,
        picture: null,
        uploadValue: 0,
      },
      colorOptions: ["red", "green", "blue", "yellow", "pink", "brown"],
      fb_storage_bucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
    };
  },
  computed: {
    ...mapState(["user", "classroomCode"]),
  },
  methods: {
    addMarker() {
      this.markerForm.user = this.user.data.id;

      data
        .addMarker(this.markerForm)
        .then((markerid) => {
          data.getMarkers(this.user.data.id).then((response) => {
            if (response && response.errorMsg) {
              this.$router.push({ name: "access" });
            } else {
              this.$store.commit("setMarkers", response);
              this.closeModal();
              this.$router.push({
                name: "originmarker",
                params: { id: markerid },
              });
            }
          });
          setTimeout(() => this.getMarkers(), 1000);
        })
        .catch(() => {});
    },
    updateMarker() {
      data
        .editMarker(this.markerForm)
        .then(() => {
          setTimeout(() => this.getMarkers(), 1000);
          this.closeModal();
        })
        .catch(() => {});
    },
    getMarkers() {
      data.getMarkers(this.user.data.id).then((response) => {
        if (response && response.errorMsg) {
          this.$router.push({ name: "access" });
        } else {
          this.$store.commit("setMarkers", response);
        }
      });
    },
    updateForm() {
      this.markerForm.title = this.marker.title;
      this.markerForm.description = this.marker.description;
      this.markerForm.evaluation = this.marker.evaluation;
      this.markerForm.color = this.marker.color;
      this.markerForm.image = this.marker.image;
      this.markerForm.max_points = this.marker.max_points;
      this.markerForm.min_points = this.marker.min_points;
      this.markerForm.id = this.marker.id;
      this.markerForm.user = this.user.data.id;

      if (this.marker.image)
        storage
          .ref(this.marker.image)
          .getDownloadURL()
          .then((url) => {
            this.imageForm.picture = url;
          })
          .catch(() => {});
      else this.imageForm.picture = null;
    },
    preloadImage(event) {
      this.imageForm.uploadValue = 0;
      this.imageForm.picture = null;
      this.imageForm.imageData = event.target.files[0];
      this.markerForm.image =
        new Date().getTime().toString(16) +
        "." +
        this.imageForm.imageData.name.substr(
          this.imageForm.imageData.name.lastIndexOf(".") + 1
        );
    },

    uploadImage() {
      this.imageForm.picture = null;
      const storageRef = storage
        .ref(this.markerForm.image)
        .put(this.imageForm.imageData);
      storageRef.on(
        `state_changed`,
        (snapshot) => {
          this.imageForm.uploadValue =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        },
        () => {
          // console.error(error.message)
        },
        () => {
          this.imageForm.uploadValue = 100;
          storageRef.snapshot.ref.getDownloadURL().then((url) => {
            this.imageForm.picture = url;
          });
        }
      );
    },
    chosseImage() {
      document.getElementById("inputImage").click();
    },
    closeModal() {
      this.$modal.hide("originMarkerForm");
    },
  },
  created() {
    if (this.marker) {
      this.updateForm();
    }
  },
  watch: {
    marker: function () {
      this.updateForm();
    },
  },
};
</script>

<style>
</style>