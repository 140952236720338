<template>
  <modal
    name="goPremiumProfile"
    :clickToClose="false"
    height="auto"
    class="form-modal"
    :scrollable="true"
  >
    <h1 class="title-modal">Activar Plan Premium</h1>
    <p class="text-center mb-3">
      Puedes elegir dos modalidades Premium: trimestral o anual. Selecciona la
      que más se adapte a tus posiblidades.
    </p>
    <p class="text-center mb-3">
      Cuando la selecciones y previo a su contratación podrás ver sus ventajas y
      disponibilidades ilimitadas, siempre con opción de cancelación.
    </p>
    <div class="flex py-4 items-center">
      <div class="w-1/2 pt-2">
        <p class="text-md-body-1 font-bold">3,99€ cada 3 meses</p>
        <p class="text-sm">¡1 mes gratis!</p>
      </div>
      <div class="w-1/2">
        <button class="w-full" @click="subscription('trimestral')">
          <span class="text-button">Seleccionar</span>
        </button>
      </div>
    </div>
    <div class="flex py-4 items-center">
      <div class="w-1/2 pt-2">
        <p class="text-md-body-1 font-bold">9,99€ al año</p>
        <p class="text-sm">¡Primer año gratis!</p>
      </div>
      <div class="w-1/2">
        <button class="w-full" @click="subscription('anual')">
          <span class="text-button">Seleccionar</span>
        </button>
      </div>
    </div>
    <div class="pt-5">
      <button class="w-full close-modal" @click="closeModal">
        <span class="text-button">Cancelar</span>
      </button>
    </div>
  </modal>
</template>

<script>
import { mapState } from "vuex";
import data from "../services/data";
export default {
  components: {},

  data() {
    return {
      loading: false,
      code: false,
    };
  },
  computed: {
    ...mapState(["user"]),
  },
  methods: {
    subscription(type) {
      // You will be redirected to Stripe's secure checkout page
      //this.$refs.checkoutRef.redirectToCheckout();
      data.startSubscription(this.user.data.id, type).then(() => {
        //console.log("StartSubscription");
      });
    },
    closeModal() {
      this.$modal.hide("goPremiumProfile");
    },
  },
};
</script>

<style>
</style>