import Vue from "vue";
import Vuex from "vuex";
import helpers from "../services/helpers";
import createPersistedState from "vuex-persistedstate";
import api from "../services/data";

Vue.use(Vuex);

const store = new Vuex.Store({
  plugins: [
    createPersistedState({
      paths: [
        "classroomCode",
        "user.loggedIn",
        "user.data.id",
        "user.data.email",
        "user.data.name",
        "user.data.role",
      ],
    }),
  ],
  state: {
    fake: "",
    user: {
      loggedIn: false,
      data: null,
    },
    classroomCode: null,
    classroom: {},
    classroomStudents: [],
    classrooms: [],
    classroomMarkers: [],
    markers: [],
    showAddToHomeScreenModalForApple: false,
  },
  actions: {
    fetchUser({ commit }, user) {
      commit("setLoggedIn", user !== null);
      if (user) {
        commit("setUser", {
          name: user.displayName,
          email: user.email,
          id: user.uid,
          role: user.role,
          subscription: user.subscription,
        });
      } else {
        commit("setUser", null);
      }
    },
    updateSubscription({ commit, state }, subscription) {
      commit("setSubscription", {
        role: subscription ? subscription.role : state.user.data.role,
        subscription: subscription,
      });
    },
    updateUserProfile({ commit }, user) {
      if (user) {
        commit("updateUser", {
          name: user.displayName,
        });
      }
    },
    closeAddToHomeScreenModalForApple: async ({ commit }) => {
      localStorage.setItem("addToHomeIosPromptLastDate", Date.now());
      commit("setShowAddToHomeScreenModalForApple", false);
    },
    searchNewCode() {
      return new Promise((resolve) => {
        let newCode = helpers.generateID();
        api.existsClassRoom(newCode).then((exists) => {
          if (exists) {
            return this.dispatch("searchNewCode");
          } else {
            resolve(newCode);
          }
        });
      });
    },
    duplicateStudent({ state }, student) {
      return new Promise((resolve, reject) => {
        api
          .addClassroomStudent(state.classroomCode, {
            name: student.name.includes("(Copia)")
              ? student.name
              : student.name + " (Copia)",
          })
          .then((newStudentID) => {
            let addMarkersPromiseArr = student.markers.map((marker) => {
              return api.editStudentMarker(
                state.classroomCode,
                newStudentID,
                marker.id,
                marker.points
              );
            });

            Promise.all(addMarkersPromiseArr).then(() => {
              resolve(newStudentID);
            });
          })
          .catch(() => {
            reject();
          });
      });
    },
    addStudentToClassroomWithMarkers({ state }, data) {
      state.fake = "";
      return new Promise((resolve) => {
        api
          .addClassroomStudent(data.classroomCode, { name: data.student.name })
          .then((newStudentID) => {
            let addMarkersPromiseArr = data.student.markers.map((marker) => {
              return api.editStudentMarker(
                data.classroomCode,
                newStudentID,
                marker.id,
                marker.points
              );
            });
            Promise.all(addMarkersPromiseArr).then(() => {
              resolve();
            });
          });
      });
    },
    duplicateClassroom({ state }, code) {
      return new Promise((resolve) => {
        api
          .addClassRoom(code, {
            name: state.classroom.name + " (Copia)",
            max_points: state.classroom.max_points,
            user: state.user.data.id,
          })
          .then(() => {
            let addStudentsPromiseArr = state.classroomStudents.map(
              (student) => {
                return api.addClassroomStudent(code, {
                  name: student.name,
                });
              }
            );
            Promise.all(addStudentsPromiseArr).then(() => {
              let classroomMarkers = Object.values(state.classroomMarkers);
              let addMarkersPromiseArr = classroomMarkers.map((marker) => {
                return api.addClassroomMarker(code, {
                  ...marker,
                  marker: { id: marker.id },
                });
              });
              Promise.all(addMarkersPromiseArr).then(() => {
                resolve();
              });
            });
          });
      });
    },
  },
  getters: {
    user(state) {
      return state.user;
    },
    userCan() {
      let permissions = {
        maxClassrooms: 1,
        maxMarkers: 10,
        maxStudentsByClassroom: 40,
        clearData: false,
        copyData: false,
        lockBadges: false,
        markNewBadges: false,
        markEarnedBadges: false,
      };

      //const role = getters.user.data.role;

      //if (role != "free") {
      permissions.maxClassrooms = Infinity;
      permissions.maxMarkers = Infinity;
      permissions.maxStudentsByClassroom = Infinity;
      permissions.clearData = true;
      permissions.copyData = true;
      permissions.lockBadges = true;
      permissions.markNewBadges = true;
      permissions.markEarnedBadges = true;
      //}

      return permissions;
    },
    rankedClassroomStudents(state) {
      return helpers.addPositionToStudents(state.classroomStudents);
    },

    student: (state) => (id) => {
      return state.classroomStudents.find((student) => student.id === id);
    },

    markersStudent: (state, getters) => (id) => {
      let student = getters.student(id);
      let markersMerged = [];
      let markers = state.classroomMarkers;

      for (const id in markers) {
        var studentMarker = student.markers.find((marker) => marker.id === id);
        markersMerged.push(
          Object.assign(
            markers[id],
            studentMarker ? studentMarker : { points: 0 }
          )
        );
      }

      return markersMerged.sort((a, b) => b.points - a.points);
    },
    classroomMarker: (state) => (id) => {
      return state.classroomMarkers[id];
    },
    originMarker: (state) => (id) => {
      return state.markers.find((marker) => marker.id === id);
    },
    rankedClassroomStudentsByMarker: (state) => (id) => {
      return helpers.filterAndAddPositionToStudentsByMarker(
        state.classroomStudents,
        id
      );
    },
  },
  mutations: {
    setLoggedIn(state, value) {
      state.user.loggedIn = value;
    },
    setUser(state, data) {
      state.user.data = data;
    },
    setSubscription(state, data) {
      state.user.data.role = data.role;
      state.user.data.subscription = data.subscription;
    },
    updateUser(state, user) {
      for (const field in user) {
        state.user.data[field] = user[field];
      }
    },
    setClassroomCode(state, val) {
      if (val) {
        state.classroomCode = val;
      } else {
        state.classroomCode = null;
      }
    },
    setClassrooms(state, val) {
      if (val) {
        state.classrooms = val;
      } else {
        state.classrooms = [];
      }
    },
    setClassroom(state, val) {
      if (val) {
        state.classroom = val;
      } else {
        state.classroom = {};
      }
    },
    setClassroomStudents(state, val) {
      if (val) {
        state.classroomStudents = val;
      } else {
        state.classroomStudents = [];
      }
    },
    setClassroomMarkers(state, val) {
      if (val) {
        state.classroomMarkers = val;
      } else {
        state.classroomMarkers = {};
      }
    },
    setMarkers(state, val) {
      if (val) {
        state.markers = val;
      } else {
        state.markers = [];
      }
    },
    setShowAddToHomeScreenModalForApple: (state, value) =>
      (state.showAddToHomeScreenModalForApple = value),
  },
});

export default store;
