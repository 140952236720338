import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import VuePageTransition from "vue-page-transition";
import LongPress from "vue-directive-long-press";
import store from "./store";
import NumberInput from "@chenfengyuan/vue-number-input";
import "./services/handle-apple-install-prompt";

import "@/assets/css/tailwind.css";

import VuejsDialog from "vuejs-dialog";
import "vuejs-dialog/dist/vuejs-dialog.min.css";

import VModal from "vue-js-modal";
import * as Sentry from "@sentry/vue";
import { Integrations } from "@sentry/tracing";

import TextareaAutosize from "vue-textarea-autosize";
Vue.use(TextareaAutosize);

Vue.config.productionTip = false;
Vue.use(VuePageTransition);
Vue.use(VuejsDialog);
Vue.use(VModal);
Vue.use(NumberInput);
Vue.directive("long-press", LongPress);
Sentry.init({
  Vue,
  dsn: process.env.VUE_APP_SENTRY_DSN,
  integrations: [new Integrations.BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
  logErrors: true
});

new Vue({
  store,
  router,
  render: h => h(App)
}).$mount("#app");
