<template>
  <div
    id="item-marker"
    class="item-marker w-1/2 inline-flex relative"
    @click="goToMarker()"
    :class="{ locked }"
  >
    <div class="container-item-marker bg-white p-4 w-full">
      <div
        class="color-marker w-5 h-5 rounded-full absolute"
        :class="marker.color"
      ></div>
      <img
        v-if="marker.image && marker.image.length"
        :src="
          'https://firebasestorage.googleapis.com/v0/b/' +
          fb_storage_bucket +
          '/o/' +
          marker.image +
          '?alt=media'
        "
        :alt="marker.title"
        class="image_item-marker mb-3"
      />
      <h3 class="title_item-marker">{{ marker.title }}</h3>
      <h4 class="max-points_item-marker flex justify-center items-center">
        Total de puntos:
        <span class="number-max-points">{{ marker.max_points }}</span>
      </h4>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "originMarkerCard",
  props: ["marker", "position"],
  data() {
    return {
      fb_storage_bucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
    };
  },
  components: {},
  computed: {
    locked() {
      return this.userCan.maxMarkers < this.position;
    },
    ...mapGetters(["userCan"]),
  },
  methods: {
    goToMarker() {
      if (this.locked) this.$modal.show("goPremium");
      else
        this.$router.push({
          name: "originmarker",
          params: { id: this.marker.id },
        });
    },
  },
};
</script>

<style lang="scss">
#markers-profile {
  display: inline-flex;
  width: 100%;
  padding: 15px;
  flex-wrap: wrap;

  #item-marker,
  .item-marker {
    padding: 0.25rem;
    transform: skew(-5deg);

    .container-item-marker {
      text-align: center;

      > * {
        transform: skew(5deg);
      }

      img,
      .image_item-marker {
        width: 60px;
        display: inline-block;
      }

      h3.title_item-marker {
        font-family: Poppins;
        font-size: 15px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: -0.3px;
        text-align: center;
        color: #ed6362;
      }
    }
  }
}

.progress_item-marker {
  align-items: center;
  margin-top: 7.5px;

  .progress-bar {
    width: 66.666667% !important;
    transform: skew(-10deg);

    .item_progress-bar_lines {
      background: #f0f0f0;
    }
  }

  .poinst_item-marker {
    font-size: 12px;
    font-weight: 600;
    color: #009688;
  }
}
</style>